import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogContentViewersViewModel } from '@app/super/catalog-details-base';
import { CatalogViewServiceBase } from '@app/super/catalog-view-service-base';
import { CatalogDetailsType } from '@enums/catalog-details-type';
import { CatalogLanguage, FeatureToggleValuesDto, ViewGroupItemDto, Viewer } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { ICatalogQueryParams, ICatalogRoutingParams } from '@interfaces/iCatalogRoutingParams';
import { IContactFormOpen } from '@interfaces/iContactFormOpen';
import { AutodeskViewerComponent } from "../../viewers/autodeskviewer/autodeskviewer.component";
import { BimobjectViewerComponent } from "../../viewers/bimobjectviewer/bimobjectviewer.component";
import { CadClickViewerComponent } from "../../viewers/cadclickviewer/cadclickviewer.component";
import { CadenasViewerComponent } from "../../viewers/cadenasviewer/cadenasviewer.component";
import { CatalogcontactpersonViewerComponent } from "../../viewers/catalogcontactperson-viewer/catalogcontactperson-viewer.component";
import { CatalogContactViewerComponent } from "../../viewers/catalogcontactviewer/catalogcontactviewer.component";
import { CatalogInfoViewerComponent } from "../../viewers/cataloginfoviewer/cataloginfoviewer.component";
import { CatalogRelationsViewerComponent } from "../../viewers/catalogrelationsviewer/catalogrelationsviewer.component";
import { FileVideoViewerComponent } from "../../viewers/filevideoviewer/filevideoviewer.component";
import { FileviewerComponent } from "../../viewers/fileviewer/fileviewer.component";
import { FolderViewerComponent } from "../../viewers/folder-viewer/folder-viewer.component";
import { IfcViewerComponent } from "../../viewers/ifcviewer/ifcviewer.component";
import { ImageViewerComponent } from "../../viewers/imageviewer/imageviewer.component";
import { LinkViewerComponent } from "../../viewers/linkviewer/linkviewer.component";
import { PdfviewerComponent } from "../../viewers/pdfviewer/pdfviewer.component";
import { ProtocolviewerComponent } from "../../viewers/protocolviewer/protocolviewer.component";
import { SustainabilityViewerComponent } from "../../viewers/sustainability-viewer/sustainability-viewer.component";
import { TextviewerComponent } from "../../viewers/textviewer/textviewer.component";
import { VimeoVideoViewerComponent } from "../../viewers/vimeovideoviewer/vimeovideoviewer.component";
import { WatchlistRootviewerComponent } from "../../viewers/watchlist-rootviewer/watchlist-rootviewer.component";
import { WatchlistUserdefinedRootviewerComponent } from "../../viewers/watchlist-userdefined-rootviewer/watchlist-userdefined-rootviewer.component";
import { YTVideoViewerComponent } from "../../viewers/ytvideoviewer/ytvideoviewer.component";

@Component({
	selector: 'app-content-viewer',
	templateUrl: './content-viewer.component.html',
	styleUrls: ['./content-viewer.component.scss'],
	standalone: true,
	imports: [LinkViewerComponent, ImageViewerComponent, PdfviewerComponent, AutodeskViewerComponent, BimobjectViewerComponent, CadenasViewerComponent, FileviewerComponent, IfcViewerComponent, VimeoVideoViewerComponent, YTVideoViewerComponent, FileVideoViewerComponent, TextviewerComponent, ProtocolviewerComponent, FolderViewerComponent, CatalogInfoViewerComponent, CatalogContactViewerComponent, CatalogRelationsViewerComponent, CatalogcontactpersonViewerComponent, WatchlistRootviewerComponent, WatchlistUserdefinedRootviewerComponent, SustainabilityViewerComponent, CadClickViewerComponent]
})
export class ContentViewerComponent implements OnInit {

	public viewer = Viewer;
	@Input() mainViewer: Viewer;
	@Input() attachment: ViewGroupItemDto;
	@Input() attachments: ViewGroupItemDto[];
	@Input() catalogKey: string;
	@Input() catalogItemGuid: string;
	@Input() url: string;
	@Input() catalogContentViewersViewModel: CatalogContentViewersViewModel
	@Input() routingParams: ICatalogRoutingParams;
	@Input() queryParams: ICatalogQueryParams;
	@Input() featureToggles: FeatureToggleValuesDto;
	@Input() catalogViewService: CatalogViewServiceBase;
	@Input() catalogDetailsType: CatalogDetailsType;
	@Input() catalogLanguage: CatalogLanguage;

	@Output() contactFormOpen = new EventEmitter<IContactFormOpen>();

	constructor() {
	}

	ngOnInit(): void { }

}
