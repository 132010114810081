export enum CatalogContentType {
	Tree = 1,
	SearchResult = 2,
	Footer = 3,
	CatalogNotFound = 4,
	NewsList = 5,
	OverviewViewer = 6
}

export enum CatalogNavigationContentType {
	Mobile = 1,
	Desktop = 2,
	MobileSearch = 3
}

export enum ActionButtonContentType {
	ExportDialog = 1,
	DownloadAndTextButton = 2,
	CopyButton = 3
}