import { Injectable } from "@angular/core";
import { ActivatedRoute, ActivatedRouteSnapshot, Router, RouterStateSnapshot } from "@angular/router";
import { AdministrationRoutingScopes } from "@app/app-routing-models";
import { TrackLoginRequestParams } from "@app/models/tracking/track-event-param-type";
import { isNullish } from "@commonHelpers/math-utils";
import { Role } from "@interfaces/HttpClient/AccountApiPublicModels";
import { LoginSource } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";
import { AccountService } from "@services/account.service";
import { AnalyticsTrackingFacadeService } from "@services/analytics-tracking-facade.service";
import { GlobalVarService } from "@services/global-var.service";
import { PreviewAuthorizationService } from "@services/preview-authorization.service";
import { UserAuthorizationService } from "@services/user-authorization.service";

// TODO Überarbeiten wenn https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/3406 gefixt ist
@Injectable({
	providedIn: "root"
})
export class AdminGuard {
	constructor(private router: Router,
		public activatedroute: ActivatedRoute,
		private userAuthorization: UserAuthorizationService,
		private previewAuthorizationService: PreviewAuthorizationService,
		private trackingFacadeService: AnalyticsTrackingFacadeService,
		private globalVarService: GlobalVarService,
		private accountService: AccountService
	) { }

	async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

		// Tracking LoginRequest wenn direkt über Urleingabe in einen geschützten Bereich(Administration, Vorschau, Styleguide) navigiert wird
		if (!this.accountService.isLoggedIn()) {
			this.trackingFacadeService.handleTrackEvent(new TrackLoginRequestParams(LoginSource.UserMenu))
			await this.trackingFacadeService.commitAllTrackEvents();
		}
		// Workaround weil die Params nicht übergeben werden
		const urlparts = state.url.split("/");
		const scope = urlparts[1] === AdministrationRoutingScopes.PREVIEW ? AdministrationRoutingScopes.PREVIEW : urlparts[2];
		const idPart = scope === AdministrationRoutingScopes.PREVIEW ? urlparts[2] : urlparts[3];
		// Klick auf den Rootknoten in der Vorschauansicht muss berücksichtigt werden, wenn zuvor auf einen Tab geklickt wurde			
		if (scope === AdministrationRoutingScopes.PREVIEW) {
			await this.accountService.isLoggedInByAcquireTokenSilent();
			const catalogKey = idPart?.includes('?') ? idPart?.substring(0, idPart?.indexOf('?')) : idPart
			const catalogId = await this.previewAuthorizationService.getCatalogIdByCatalogKeyForPreview(catalogKey);
			if (isNullish(catalogId)) {
				if (!this.globalVarService.hasRedirect()) {
					await this.router.navigate(['/']);
				}

				return false;
			}
			if (await this.userAuthorization.hasCatalogRole(catalogId.toString(), Role.CatalogViewer)
				|| await this.userAuthorization.hasCatalogRole(catalogId.toString(), Role.CatalogAdministrator)) {
				return true
			}

		}
		const id = idPart?.includes('?') ? idPart?.substring(0, idPart?.indexOf('?')) : idPart
		// User can access catalog administration for all catalogs, she or he has read rights or read and write rights for
		if (scope === AdministrationRoutingScopes.CATALOG) {
			// nested if statements to avoid unnecessary http requests
			if (await this.userAuthorization.hasCatalogRole(id, Role.CatalogViewer)
				|| await this.userAuthorization.hasCatalogRole(id, Role.CatalogAdministrator)) {
				return true
			}
		}
		// User can access licence administration for all licences, she or he has read rights or read and write rights for
		if (scope === AdministrationRoutingScopes.COMPANIES) {
			// nested if statements to avoid unnecessary http requests
			if (await this.userAuthorization.hasCompanyRole(id, Role.LicenseViewer)
				|| await this.userAuthorization.hasCompanyRole(id, Role.LicenseAdministrator)) {
				return true
			}
		}
		// User can access user administration
		if (scope === AdministrationRoutingScopes.USER) {
			if (await this.userAuthorization.hasRole(Role.Visitor)) {
				return true;
			}
		}

		// User can access all of administration, if she or he has the role Orca Admin
		if (await this.userAuthorization.hasRole(Role.Admin)) {
			return true;
		}

		// User can access all of administration, if she or he has the role AccountManager Administrator
		if (await this.userAuthorization.hasRole(Role.AccountManagerAdministrator)) {
			return true;
		}

		// User can access all of administration, if she or he has the role AccountManager Administrator
		if (await this.userAuthorization.hasRole(Role.AccountManagerViewer)) {
			return true;
		}

		// If user has none of the required roles, redirect her or him to home page
		if (!this.globalVarService.hasRedirect()) {
			await this.router.navigate(['/']);
		}
		return false;
	}

}
