import { HostListener, Injectable } from '@angular/core';
import { generateGuid } from '@app/helpers/guid-generator';
import { isNullish } from '@commonHelpers/math-utils';
import { BehaviorSubject, Subscription, map } from 'rxjs';
import { GlobalVarService } from './global-var.service';

@Injectable({
	providedIn: 'root'
})
export class UserService {
	private user = new BehaviorSubject<ILocalUserItem>(null)
	private userServiceSubscription: Subscription;

	private currentUser: ILocalUserItem;
	private readonly expireTimeInYears = 1;

	constructor(private globalVarService: GlobalVarService) {
	}

	public init(): void {

		if (isNullish(this.userServiceSubscription)) {
			this.userServiceSubscription = this.getAsObservable().subscribe(currentUser => this.currentUser = currentUser)
		}

		const user = this.globalVarService.getUser()

		if (isNullish(user)) { return }

		if (this.isUserExpired(user)) { return }
		this.user.next(user)
	}

	set(value: ILocalUserItem) {
		this.globalVarService.setUser(value)
		this.user.next(value)
	}

	getAsObservable() {
		return this.user.asObservable().pipe(map((user) => this.isUserExpired(user) ? null : user))
	}

	private isUserExpired(user: ILocalUserItem): boolean {
		const currentTime = new Date();
		if (!isNullish(user) && new Date(user.expire).getTime() < currentTime.getTime()) {
			this.globalVarService.deleteUser();
			return true
		}
		return false
	}

	public getOrCreateUserId(): string {
		this.currentUser = this.globalVarService.getUser();
		const localStorageExpireDate = new Date();
		localStorageExpireDate.setFullYear(new Date().getFullYear() + this.expireTimeInYears);
		if (isNullish(this.currentUser)) {
			// user ganz neu anlegen
			const newUserId = generateGuid()
			this.set({
				guid: newUserId,
				expire: localStorageExpireDate
			})
			return newUserId;
		}
		return this.currentUser?.guid;
	}

	@HostListener('window:beforeunload')
	destroy(): void {
		this.userServiceSubscription?.unsubscribe()
	}
}

export interface ILocalUserItem {
	guid: string,
	expire: Date,
}
