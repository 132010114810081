import { Injectable } from '@angular/core';
import { RequestService } from '@commonServices/request.service';
import { Client as CatalogPublicAPI } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { firstValueFrom, of } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class PreviewAuthorizationService {

	constructor(private catalogPublicAPI: CatalogPublicAPI, private requestService: RequestService) { }

	private static currentCatalogId: { key: string; value: number };

	async getCatalogIdByCatalogKeyForPreview(
		catalogKey: string
	): Promise<number> {
		try {
			if (PreviewAuthorizationService.currentCatalogId?.key === catalogKey) {
				return firstValueFrom(of(PreviewAuthorizationService.currentCatalogId?.value))
			}
			const result = await firstValueFrom(this.catalogPublicAPI.common_GetCatalogIdByCatalogKeyForPreview(catalogKey));
			PreviewAuthorizationService.currentCatalogId = {
				key: catalogKey, value: result
			};
			return result;
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}
}
