import { Directive, HostListener } from '@angular/core';
import { ContextMenuService } from '@services/context-menu.service';
import { timer } from 'rxjs';

@Directive({
	selector: '[appContextMenuOpen]',
	standalone: true
})
export class ContextMenuOpenDirective {

	constructor(
		private contextMenuService: ContextMenuService
	) { }

	@HostListener('contextmenu', ['$event'])
	onContextMenu(event: MouseEvent) {
		this.contextMenuService.openMenu(
			event.clientX, event.clientY
		)

		// if propagation is not stopped, the event bubbles up to app component and triggers closing of the menu
		event.stopPropagation()
		// if function does not return false, standard context menu is shown

		const closeMenuEvent = (e: Event) => {
			timer(300).subscribe(() => {
				this.contextMenuService.closeMenu();
				window.removeEventListener('click', closeMenuEvent);
				window.removeEventListener('focusout', closeMenuEvent);
			});
		}
		window.addEventListener('click', closeMenuEvent)
		window.addEventListener('focusout', closeMenuEvent)

		return false
	}
}
