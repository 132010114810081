<div class="h-100 w-100 fit-content overflow-hidden pt-3">
	@if (catalog) {
		<app-catalog-details
			#catalogDetails
			[catalogDetailsType]="catalogDetailsType"
			[queryParams]="catalogQueryParams"
			[routingParams]="catalogRoutingParams"
			(contactFormOpen)="openContactForm($event)"
			[catalogKey]="catalog.key"
			[hasContactPersons]="catalog.hasContactPersons"
			[featureToggles]="catalog.featureToggles"
			[catalogViewForeignLanguageCatalogsDto]="languageCatalogs"
			[catalogTitle]="catalog?.title"
			[catalogViewRelatedCatalogsDto]="relatedCatalogs">
		</app-catalog-details>
	}
</div>
