import { Injectable } from '@angular/core';
import { isNullish } from '@commonHelpers/math-utils';
import { ViewGroup } from '@interfaces/HttpClient/CatalogApiPublicModels';

@Injectable()
export class TabService {

	private tabgroups: ViewGroup[][] = [
		[ViewGroup.Unknown],
		[ViewGroup.Protocol],
		[ViewGroup.CatalogInfo],
		[ViewGroup.CatalogContact],
		[ViewGroup.CatalogContactPersons],
		[ViewGroup.CatalogRelations],
		[ViewGroup.Overview],
		[ViewGroup.OverviewFolder],
		[ViewGroup.ImageAndVideo, ViewGroup.Images, ViewGroup.Videos],
		[ViewGroup.Documents],
		[ViewGroup.CadAndBim],
		[ViewGroup.Sustainability],
		[ViewGroup.LinkAndComment, ViewGroup.Links, ViewGroup.Comments],
		[ViewGroup.Error],
		[ViewGroup.WatchlistFolder],
		[ViewGroup.WatchlistUserdefinedFolder]
	]

	constructor() { }

	public getTabValue(queryParamTab: string, currentTabs: ViewGroup[]): ViewGroup {
		if (queryParamTab) {
			const tabgroup = this.tabgroups.find(group => group.some(item => ViewGroup[queryParamTab] === item));
			if (!isNullish(tabgroup)) {
				const tabRequest = currentTabs?.find(tab => tabgroup.includes(tab));
				if (tabRequest) {
					return tabRequest;
				}
			}
		}

		return currentTabs?.find(tab => tab !== undefined)
	}
}
