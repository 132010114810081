import { Injectable } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TrackLoginRequestParams } from "@app/models/tracking/track-event-param-type";
import { isNullish } from "@commonHelpers/math-utils";
import { MsalBroadcastService } from "@commonNodeModules/@azure/msal-angular";
import { AccountLanguage } from "@interfaces/HttpClient/AccountApiPublicModels";
import { LoginSource } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";
import { AccountService } from "@services/account.service";
import { AnalyticsTrackingFacadeService } from "@services/analytics-tracking-facade.service";
import { GlobalVarService } from "@services/global-var.service";
import { TranslationService } from "@services/translation.service";

@Injectable({
	providedIn: "root"
})
export class TrackOnboardingGuard {
	constructor(
		public activatedroute: ActivatedRoute,
		private trackingFacadeService: AnalyticsTrackingFacadeService,
		private accountService: AccountService,
		private globalVarService: GlobalVarService,
		private msalBroadcastService: MsalBroadcastService,
		private translationService: TranslationService
	) { }

	async canActivate() {
		if (!this.accountService.isLoggedIn()) {
			this.trackingFacadeService.handleTrackEvent(new TrackLoginRequestParams(LoginSource.OnBoard))
			await this.trackingFacadeService.commitAllTrackEvents();
		}

		const onboardingIdFromUrl = new URLSearchParams(window.location.search).get("onBoardingId");
		const onboardingLanguageFromUrl = new URLSearchParams(window.location.search).get("language");

		// Account Sprache festlegen
		let lang = AccountLanguage.German // default
		switch (onboardingLanguageFromUrl) {
			case 'German':
				lang = AccountLanguage.German;
				break;
			case 'English':
				lang = AccountLanguage.English;
				break;
		}
		await this.translationService.switchAdminLanguage(lang);

		if (!isNullish(onboardingIdFromUrl) && !Number.isNaN(+onboardingIdFromUrl) && (+onboardingIdFromUrl) !== 0) {
			this.globalVarService.setOnboardingId(+onboardingIdFromUrl);
			this.globalVarService.setOnboardingLanguage(onboardingLanguageFromUrl);
		}

		return true;
	}

}
