import { Injectable } from '@angular/core';

@Injectable({
	providedIn: 'root'
})
export class UrlService {
	private previousUrl: string;

	constructor() { }

	setPreviousUrl(previousUrl: string) {
		this.previousUrl = previousUrl
	}

	getPreviousUrl() {
		return this.previousUrl
	}

}


