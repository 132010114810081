import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { globalEnvironment } from '@environments/globalEnvironment';

@Injectable({
	providedIn: 'root'
})
export class LogoService {

	constructor() { }
	private optimizedType = "image/avif";

	public getLargeLogosByKey(key: string): LogoDto {
		return {
			optimizedSrc: environment.blobUrl + globalEnvironment.catalogLogoPath.replaceAll('{key}', key).replace('{size}', 'large').replace('{format}', 'avif'),
			fallbackSrc: environment.blobUrl + globalEnvironment.catalogLogoPath.replaceAll('{key}', key).replace('{size}', 'large').replace('{format}', 'png'),
			optimizedType: this.optimizedType
		}
	}

	public getSmallLogosByKey(key: string): LogoDto {
		return {
			optimizedSrc: environment.blobUrl + globalEnvironment.catalogLogoPath.replaceAll('{key}', key).replace('{size}', 'small').replace('{format}', 'avif'),
			fallbackSrc: environment.blobUrl + globalEnvironment.catalogLogoPath.replaceAll('{key}', key).replace('{size}', 'small').replace('{format}', 'png'),
			optimizedType: this.optimizedType
		}
	}


}

export interface LogoDto {
	optimizedSrc: string;
	fallbackSrc: string;
	optimizedType: string;
}
