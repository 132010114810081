@if (showContent) {
	<div [class.init-show]="initShow" class="position-absolute hover-position dialog-handle init-show">
		<div class="position-fixed overflow-hidden fixed-position">
			<a
				[href]="infoletterLink"
				(click)="infoLetterClicked()"
				target="_blank"
				rel="noreferrer"
				class="d-flex flag-border mb-2 bg-catalog-manager">
				<div class="flag-shadow pb-2 content d-flex align-items-center fw-bold cursor-pointer">
					<div class="ms-4 mt-3 text-white">
						{{ 'global.infoletter.title' | translate }}
					</div>
				</div>
				<div class="small-flag flag-shadow flag-border bg-catalog-manager p-2 cursor-pointer">
					<div class="mask-settings infoletter-mask-img bg-white item-icon"></div>
				</div>
			</a>
		</div>
	</div>
}
