import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { InfoletterHoverComponent } from '@app/components/global-info-hover/infoletter-hover/infoletter-hover.component';
import { isInIframe } from '@app/helpers/iframe-helper';
import { ButtonComponent } from '@commonComponents/button/button.component';
import { TranslateModule } from '@commonNodeModules/@ngx-translate/core';
import { ViewType } from '@enums/view-type';
import { environment } from '@environments/environment';
import { ALERT_DISPLAY_TYPES, ALERT_POSITION_TYPES, AlertService } from '@services/alert.service';
import { TranslationService } from '@services/translation.service';
import { ViewTypeService } from '@services/view-type.service';
import { Subscription } from 'rxjs';

declare const CCM;

@Component({
	selector: 'app-header-burger-menu',
	templateUrl: './header-burger-menu.component.html',
	styleUrls: ['./header-burger-menu.component.scss'],
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	imports: [NgClass, RouterLink, ButtonComponent, TranslateModule]
})
export class HeaderBurgerMenuComponent implements OnInit, OnDestroy {


	constructor(
		private viewTypeService: ViewTypeService,
		private router: Router,
		private elRef: ElementRef,
		private alertService: AlertService,
		private translationService: TranslationService,
		private cdRef: ChangeDetectorRef) { }

	public model: HeaderBurgerMenu[];
	public currentStateClass = 'd-none';
	public viewTypeClass: string;
	private subscription: Subscription;
	public isOpen = false;
	public currentView: ViewType;
	public viewType = ViewType;
	private static isOpen = false;
	private navigationClicked = false;
	public showCapture = false;

	@Input() canNavigateToStartingPage: boolean;
	@Input() isPreview = false;
	@Input() isInIntegrationMode = false;

	ngOnInit(): void {
		this.router.routerState.root.params.subscribe(() => {
			this.closeDialog();
		})
		this.currentStateClass = HeaderBurgerMenuComponent.isOpen ? 'svh-100' : 'd-none';
		this.subscription = this.viewTypeService.currentViewType$.subscribe(viewType => {
			this.viewTypeClass = viewType === ViewType.Desktop ? 'desktop' : 'mobile';
			this.currentStateClass = HeaderBurgerMenuComponent.isOpen ? 'svh-100' : 'd-none';
			this.isOpen = HeaderBurgerMenuComponent.isOpen;
			if (this.isOpen) {
				this.showCapture = true;
			}
			this.currentView = viewType;
			this.initModel(viewType);
		})

	}

	private initModel(viewType: ViewType) {
		this.model = [
			{
				keypart: 'experience',
				links: [
					{ keypart: 'planer', href: environment.planerUrl, target: '_blank' },
					{ keypart: 'product-manufacturer', href: environment.manufacturerUrl, target: '_blank' },
					{ keypart: 'object-consultant', href: environment.objectConsultantUrl, target: '_blank' },
					{ keypart: 'software-manufacturer', href: environment.softwareUrl, target: '_blank' }
				]
			}
		]
		const navigationTarget = isInIframe() ? '_blank' : undefined
		if (this.canNavigateToStartingPage) {
			this.model.unshift({
				keypart: 'start',
				links: [
					{ keypart: 'startpage', route: ['/'], target: navigationTarget },
					{ keypart: 'category', route: ['/category'], target: navigationTarget },
					{ keypart: 'az', route: ['/a-z'], target: navigationTarget },
					{ keypart: 'international-catalogs', route: ['/international-catalogs'], target: navigationTarget }
				]
			})
		}
		if (viewType === ViewType.Mobile) {
			this.model.push({
				keypart: 'rights',
				links: [
					{ keypart: 'imprint', route: ['/impressum'], target: '_blank' },
					{ keypart: 'legal-notice', route: ['/rechtliche-hinweise'], target: '_blank' },
					{ keypart: 'data-protection', route: ['/datenschutz'], target: '_blank' },
					{ keypart: 'cookie-settings', customKey: 'cookie-settings' }
				]
			})
		}

		// Integration hat immer nur die Footer Links im Burger Menu
		if (this.isInIntegrationMode) {
			this.model = [
				{
					keypart: 'rights',
					links: [
						{ keypart: 'imprint', route: ['/impressum'], target: '_blank' },
						{ keypart: 'legal-notice', route: ['/rechtliche-hinweise'], target: '_blank' },
						{ keypart: 'data-protection', route: ['/datenschutz'], target: '_blank' },
						{ keypart: 'cookie-settings', customKey: 'cookie-settings' }
					]
				}
			]
		}
	}

	public showPreviewWarning() {
		this.alertService.sendMessage(ALERT_POSITION_TYPES.GLOBAL, ALERT_DISPLAY_TYPES.WARNING, this.translationService.getByKey("preview.notify-message"))
	}

	ngOnDestroy(): void {
		this.subscription?.unsubscribe()
	}

	public get infoletterLink() {
		return environment.infoletterUrl;
	}

	public triggerMenu() {
		this.navigationClicked = false
		this.isOpen = !this.isOpen;
		this.currentStateClass = this.isOpen ? 'fade-in' : 'fade-out'
		HeaderBurgerMenuComponent.isOpen = this.isOpen;
		if (this.isOpen) {
			this.elRef.nativeElement.querySelector(".header-navigation").focus();
			this.showCapture = true;
		}
		this.cdRef.markForCheck();
	}

	public triggerMenuMouseDown() {
		this.navigationClicked = true;
	}

	public closeOnOutside(event: FocusEvent) {
		if (!this.navigationClicked && this.isOpen) {
			this.triggerMenu();
		}
	}

	public closeDialog() {
		this.isOpen = false;
		HeaderBurgerMenuComponent.isOpen = false;
		this.currentStateClass = 'd-none'
	}

	public infoLetterClicked() {
		InfoletterHoverComponent.infoletterClicked = true;
		this.navigationClicked = false;
	}

	public loginMouseDown(event: MouseEvent) {
		event.preventDefault();
	}

	public openCookieSettings() {
		try {
			CCM?.openControlPanel();
		} catch {
			return false;
		}
	}

}

export interface HeaderBurgerMenu {
	keypart: string;
	links: {
		keypart: string,
		route?: string[],
		href?: string,
		target?: string,
		customKey?: string
	}[]
}
