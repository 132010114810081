<div
	ngbDropdown
	(click)="navigateToWatchlist()"
	role="link"
	[class.cursor-unset]="!canNavigate()"
	class="btn toggle-dropdown p-0 border-0"
	#watchlistDropdown="ngbDropdown"
	placement="bottom-right"
	dropdownClass="dropdown-zindex"
	[container]="'body'"
	(mouseenter)="onHoverDropdown()"
	(mouseleave)="onLeaveDropdown()">
	<div
		ngbDropdownToggle
		id="watchlist-dropdown"
		class="d-flex gap-2 icon-container-size align-items-center justify-content-center"
		role="link"
		[class.desktop-min-width]="!isMobile"
		[ngClass]="[theme === 'neutral' ? 'bg-primary' : 'bg-white', itemsCount > 0 ? 'px-3' : 'px-2']">
		<img [src]="watchlistIcon" loading="lazy" class="icon-size" decoding="async" alt="{{ 'watchlist.header' | translate }}" />
		@if (itemsCount > 0) {
			<div>
				{{ itemsCount }}
			</div>
		} @else if (itemsCount === 0 && showWatchlistTitle) {
			<div>
				{{ 'global.watchlist' | translate }}
			</div>
		}
	</div>

	<div
		[class.d-none]="!this.canNavigate()"
		id="watchlistHelp"
		ngbDropdownMenu
		(mouseenter)="onHoverDropdown()"
		(mouseleave)="onLeaveDropdown()">
		<div class="w-100 px-4 pt-3 pb-3 d-flex flex-column align-items-center bg-gray-200" style="max-width: calc(100vw - 30px)">
			<div class="h5 text-center" style="line-height: 24px">
				{{ 'watchlist-header-navigation.title' | translate }}
			</div>
			<div class="text-center">
				<span>{{
					'watchlist-header-navigation.description.' + (itemsCount === 1 ? 'singular' : 'plural')
						| translate: { count: itemsCount }
				}}</span>
			</div>
		</div>
	</div>
</div>
@if (showInternalWatchlist) {
	@defer {
		<app-internal-watchlist-navigation [currentLanguage]="currentLanguage" [theme]="theme" />
	}
}
