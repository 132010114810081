export enum ValidationMode {
	NotEmptyOrNullish = 1,
	NotNullish = 2,
	NotLowerZero = 3,
	NotLowerOne = 4,
	NotListEmpty = 5,
	Guid = 6,
	NotPastDate = 7,
	Url = 8,
	Mail = 9,
	Phone = 10
}