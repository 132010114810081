import { Directive } from "@angular/core";
import { ViewGroupItemDto } from "@interfaces/HttpClient/CatalogApiModels";
import { CatalogItemFolderViewDto, CatalogViewContactListDto, CatalogViewGroupDto, DgnbDto } from "@interfaces/HttpClient/CatalogApiPublicModels";

@Directive()
export abstract class CatalogViewServiceBase {
	abstract getFolderView(catalogGuids: string[], catalogKey?: string): Promise<CatalogItemFolderViewDto[]>
	abstract getCatalogMeta(key: string): Promise<CatalogViewGroupDto>
	abstract getContactPersonsView(catalogKey?: string): Promise<CatalogViewContactListDto>
	abstract getDgnbSustainabilityData(catalogItemGuid: string): Promise<DgnbDto>
	abstract getAttachments(): ViewGroupItemDto[];
	abstract setAttachments(attachments: ViewGroupItemDto[]);
}
