import { Injectable } from '@angular/core';
import { isNullish } from '@commonHelpers/math-utils';
import { CatalogIntegrationCatalogViewerDto, CatalogIntegrationType } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { BehaviorSubject } from 'rxjs';
import { GlobalVarService } from './global-var.service';

// { providedIn: 'root' } nicht entfernen! Sonst wird der Service nicht als Singleton instanziert, damit ist der State nicht 
// unique und die Komponenten im integration.module können nicht mit denen im catalog-base.module kommunizieren.
@Injectable({ providedIn: 'root' })
export class CatalogIntegrationSettingsService {

	private settingsSubject = new BehaviorSubject<CatalogIntegrationCatalogViewerDto>({
		catalogIntegrationType: CatalogIntegrationType.CatalogViewer,
		canNavigateToStartingPage: true,
		showForeignLanguageCatalogs: true,
		showRelatedCatalogs: true,
		canHighlightCatalogItem: true,
		canSearch: true,
		neutralDesign: false,
		collapseNavigation: false
	})
	private integrationKeySubject = new BehaviorSubject<string>(undefined)
	private catalogIntegrationTypeSubject = new BehaviorSubject<CatalogIntegrationType>(CatalogIntegrationType.None)
	private isInIntegrationModeSubject = new BehaviorSubject<boolean>(false)

	get integrationKeyFromUrl() {
		return new URLSearchParams(window.location.search).get("integrationKey")
	}

	set settings(state: CatalogIntegrationCatalogViewerDto) {
		this.globalVarService.setSessionStorageVar(`integrationSettings-${this.integrationKeyFromUrl}`, JSON.stringify(state))
		this.settingsSubject.next(state)
	}

	get settings$() {
		return this.settingsSubject.asObservable()
	}

	set integrationKey(state: string) {
		this.globalVarService.setSessionStorageVar(`integrationKey-${this.integrationKeyFromUrl}`, JSON.stringify(state))
		this.integrationKeySubject.next(state)
	}

	get integrationKey$() {
		return this.integrationKeySubject.asObservable()
	}

	set catalogIntegrationType(type: CatalogIntegrationType) {
		this.globalVarService.setSessionStorageVar(`integrationType-${this.integrationKeyFromUrl}`, JSON.stringify(type))
		this.catalogIntegrationTypeSubject.next(type)
	}

	get catalogIntegrationType$() {
		return this.catalogIntegrationTypeSubject.asObservable()
	}

	set isInIntegrationMode(value: boolean) {
		this.globalVarService.setSessionStorageVar(`isInIntegrationMode-${this.integrationKeyFromUrl}`, JSON.stringify(value))
		this.isInIntegrationModeSubject.next(value)
	}

	get isInIntegrationMode$() {
		return this.isInIntegrationModeSubject.asObservable()
	}

	constructor(private globalVarService: GlobalVarService) {
		this.initializeFromSessionStorage()
	}

	private initializeFromSessionStorage() {
		// Website-Integration: Prüfen, ob im Session Storage für das entsprechende Fenster Settings bzw. ein Typ hinerlegt sind und gegebenenfalls nachladen.
		// Die Zwischenspeicherung im Session Storage  wird benötigt, weil die Informationen im catalogIntegrationSettingsService sonst bei der Anmeldung durch den Page-Reload verloren gehen würden.
		// Der Session Storage wird verwendet, damit die Settings nur innerhalb eines Tabs und nicht tabübergreifend gespeichert werden.
		// Falls mehrere IFrames auf derselben Seite eingebunden werden,erfolgt die Zuordnung der Settings über den integrationKey, der als QueryParameter mit übergeben wird.
		// So kann sicher gestellt werden, dass die Settings dem richtigen Iframe zugeordnet werden.
		const sessionStorageMapping: { sessionStorageKey: string, serviceSubject: BehaviorSubject<any> }[] = [
			{ sessionStorageKey: `integrationSettings-${this.integrationKeyFromUrl}`, serviceSubject: this.settingsSubject },
			{ sessionStorageKey: `integrationKey-${this.integrationKeyFromUrl}`, serviceSubject: this.integrationKeySubject },
			{ sessionStorageKey: `integrationType-${this.integrationKeyFromUrl}`, serviceSubject: this.catalogIntegrationTypeSubject },
			{ sessionStorageKey: `isInIntegrationMode-${this.integrationKeyFromUrl}`, serviceSubject: this.isInIntegrationModeSubject },
		]

		this.globalVarService.setIntegrationUrl(window.location.href);
		sessionStorageMapping.forEach(item => {
			const persistedValue = this.globalVarService.getSessionStorageVar(item.sessionStorageKey)
			if (!isNullish(persistedValue)) {
				item.serviceSubject.next(JSON.parse(persistedValue))
			}
		})
	}
}
