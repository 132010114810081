// ACHTUNG!!! SEO-kritische Komponente, Direkte Importe und Backendaufrufe sollten so gering wie nur möglich gehalten werden 


import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isNullish } from '@commonHelpers/math-utils';
import { NgbModal, NgbModalOptions } from '@commonNodeModules/@ng-bootstrap/ng-bootstrap';
import { CatalogDetailsType } from '@enums/catalog-details-type';
import { CatalogViewCatalogInformationDto, ContactFormType } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { ICatalogQueryParams, ICatalogRoutingParams } from '@interfaces/iCatalogRoutingParams';
import { IContactFormOpen } from '@interfaces/iContactFormOpen';
import { CatalogTreeService } from '@services/catalog-tree.service';
import { CatalogViewService } from '@services/catalog-view.service';
import { TranslationService } from '@services/translation.service';
import { CatalogDetailsComponent } from "../catalog-details/catalog-details.component";

@Component({
	selector: 'app-catalog-mobile-details',
	standalone: true,
	providers: [CatalogTreeService, CatalogViewService],
	templateUrl: './catalog-mobile-details.component.html',
	styleUrls: ['./catalog-mobile-details.component.scss'],
	imports: [CatalogDetailsComponent]
})
export class CatalogMobileDetailsComponent implements OnInit {

	@Input() catalog: CatalogViewCatalogInformationDto;
	@Input() catalogKey: string;
	@ViewChild("catalogDetails") catalogDetails: CatalogDetailsComponent;
	public catalogDetailsType = CatalogDetailsType.Public;
	public url: string;
	public catalogRoutingParams: ICatalogRoutingParams;
	public catalogQueryParams: ICatalogQueryParams;

	constructor(
		private route: ActivatedRoute,
		private router: Router,
		private modalService: NgbModal,
		private translationService: TranslationService) {

	}


	ngOnInit(): void {
		this.route.params.subscribe(async params => {
			this.url = this.router.url;
			this.catalogRoutingParams = params;

		});
		this.route.queryParams.subscribe(async params => {
			this.catalogQueryParams = params;
		});
	}

	async openContactForm(item: IContactFormOpen) {
		const catalogInfoOrPosition = this.catalogRoutingParams.catalogItemGuid ? this.catalogDetails.currentMainAttachment?.title : this.translationService.getByKey('catalog-view.roottitle');
		const options: NgbModalOptions = {
			backdrop: 'static',
			modalDialogClass: 'contact-form'
		};
		const contactForm = (await import('@app/components/contact-form/contact-form.component')).ContactFormComponent
		const modalRef = this.modalService.open(contactForm, options);
		modalRef.componentInstance.catalog = this.catalog;
		modalRef.componentInstance.catalogItemGuid = this.catalogRoutingParams.catalogItemGuid ? this.catalogRoutingParams.catalogItemGuid : undefined;
		modalRef.componentInstance.catalogInfoOrPosition = catalogInfoOrPosition;
		if (!isNullish(item.personId)) {
			modalRef.componentInstance.contactPersonId = item.personId;
		}
		modalRef.componentInstance.type = isNullish(this.catalogRoutingParams.catalogItemGuid) ? item.contactFormType : ContactFormType.CatalogItemContact;
	}


}
