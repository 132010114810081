import { Injectable } from '@angular/core';
import { INode } from '@interfaces/iNode';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SoftwarepartnerApiSelectionService {
  private selectedCatalogItemsSubject: BehaviorSubject<INode[]> = new BehaviorSubject<INode[]>([]);

  constructor() { }

  setSelectedCatalogItems(catalogItems: INode[]) {
    this.selectedCatalogItemsSubject.next(catalogItems);
  }

  getAsObservable(): Observable<INode[]> {
    return this.selectedCatalogItemsSubject.asObservable();
  }
}
