import { Injectable } from '@angular/core';

// Bitte injection auf "root" lassen. Der Import in einem der modules führt zu dem Problem, dass der Service manchmal nicht rechtzeitig geladen wird.
// Dadurch stehen CSS-Variablen z.B. für die Responsive Darstellung nicht rechtzeitig zur Verfügung, was zu Fehlern führt.
@Injectable({ providedIn: 'root' })
export class CssVariableService {

	constructor() { }

	getByName(name: string): string {
		const PREFIX = '--bs-';
		const bodyStyles = window.getComputedStyle(document.body);
		return bodyStyles.getPropertyValue(PREFIX + name);
	}

	getByNameAsNumber(name: string): number {
		const string = this.getByName(name)
		const stringWithoutUnit = Array.from(string).filter(char => !isNaN(Number(char)) || char === ".")
		return Number(stringWithoutUnit.join(""))
	}
}
