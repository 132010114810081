import { HttpStatusCode } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { InvalidOperationException } from '@app/exceptions/invalid-operation-exception';
import { RequestService } from '@commonServices/request.service';
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs';
import { isGuid } from '../helpers/guid-generator';
import { Client as AccountPublicAPI, SoftwarepartnerApiSessionDto } from '../interfaces/HttpClient/AccountApiPublicModels';

@Injectable({
	providedIn: 'root'
})
export class SoftwarepartnerApiSessionService {
	private apiSessionSubject: BehaviorSubject<SoftwarepartnerApiSessionDto | null> = new BehaviorSubject<SoftwarepartnerApiSessionDto | null>(null);

	constructor(private accountApi: AccountPublicAPI, private requestService: RequestService) { }

	async setAsync(apiSessionId: string): Promise<void> {
		if (!isGuid(apiSessionId)) { throw new InvalidOperationException(`apiSessionId ${apiSessionId} is not a guid.`); }
		const apiSession = await this.getSessionAsync(apiSessionId);

		if (apiSession === null) { throw new InvalidOperationException(`apiSession with id ${apiSessionId} not found.`); }
		this.apiSessionSubject.next(apiSession)
	}

	private async getSessionAsync(
		apiSessionId: string
	): Promise<SoftwarepartnerApiSessionDto | null> {
		try {
			return await firstValueFrom(this.accountApi.softwarepartnerApiPublic_GetSession(apiSessionId));
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HttpStatusCode.NoContent, return: undefined }
			]);
		}
	}

	getAsObservable(): Observable<SoftwarepartnerApiSessionDto | null> {
		return this.apiSessionSubject.asObservable()
	}
}
