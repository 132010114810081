import { HttpStatusCode } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { ExportSource, LoginSource } from '@interfaces/HttpClient/AnalyticsApiTrackingModels';
import { CadenasDto, ViewGroupItemDto } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { ICadenas } from '@interfaces/iCadenas';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { ALERT_DISPLAY_TYPES, AlertService } from './alert.service';
import { MetadataDownloadService } from './metadata-download.service';
import { TranslationService } from './translation.service';
import { ViewTypeService } from './view-type.service';

@Injectable({
	providedIn: 'root'
})
export class CadenasDownloadService {

	constructor(
		private metadataDownloadService: MetadataDownloadService,
		private alertService: AlertService,
		private viewTypeService: ViewTypeService,
		private translationService: TranslationService
	) { }

	private static openDialog = new EventEmitter();
	private static cadenas: CadenasDto;
	private static cadenasData: ICadenas;
	private static attachment: ViewGroupItemDto;
	private static catalogKey: string;
	private static catalogItemGuid: string;

	public async triggerManualDownload(qualifier: string) {
		// Objekte dürfen nicht geändert zum Metadatadownloadservice, deshalb klone der 3 Oblekte erstellt
		const attachment = JSON.parse(JSON.stringify(CadenasDownloadService.attachment));
		const catalogKey = JSON.parse(JSON.stringify(CadenasDownloadService.catalogKey));
		const catalogItemGuid = JSON.parse(JSON.stringify(CadenasDownloadService.catalogItemGuid));

		const service = await fetch(`${CadenasDownloadService.cadenasData.baseUrl}/service/mident?language=english&maxlen=-1&path=${CadenasDownloadService.cadenas.path}&type=mident&selectrowlineid=-1&selectrowlineidlow=-1&varsettransfer=${CadenasDownloadService.cadenas.configuration}`);
		const serviceText = encodeURIComponent(await service?.text());
		if (service.status === HttpStatusCode.Ok) {
			const contentUrlRequest = await fetch(`${CadenasDownloadService.cadenasData.baseUrl}/cgi-bin/cgi2pview.cgi?cgiaction=download&part=${serviceText}&apikey=${CadenasDownloadService.cadenasData.apiKey}&format=${qualifier}&language=${this.getCadenasLanguage(CadenasDownloadService.cadenasData.language)}&downloadflags=ZIP&ok_url=%3C%25download_xml%25%3E&ok_url_type=text&server_type=${CadenasDownloadService.cadenasData.userServerType}&userfirm=${CadenasDownloadService.cadenasData.userUserfirm}`);
			const downloadContentUrl = await contentUrlRequest?.text();
			if (contentUrlRequest.status === HttpStatusCode.Ok) {
				let loaded = false;
				interval(1000).pipe(takeWhile(() => !loaded)).subscribe(async () => {
					const content = await fetch(downloadContentUrl);
					if (content.status === HttpStatusCode.Ok) {
						loaded = true
						const xml = await content?.text();
						const XMLParser = (await import('fast-xml-parser')).XMLParser;
						const parser = new XMLParser();
						const parsed = parser.parse(xml)
						if (parsed) {
							this.metadataDownloadService.removeFromQueue();
							if (parsed?.ORDER?.MESSAGE) {
								this.alertMsg(
									this.translationService.getByKey("catalog-view.export-dialog.cadenas-download-error")
									+ parsed?.ORDER?.MESSAGE			// Meldung von Cadenas ausgeben
								);
							} else {
								try {
									await this.metadataDownloadService.downloadFile(attachment, LoginSource.Export, ExportSource.ExportDialog, catalogKey, catalogItemGuid, `${CadenasDownloadService.cadenasData.baseUrl}/partserver/preview/${parsed.ORDER.ORDERNO}/${parsed.ORDER.SECTION.ZIPFILE}`);
								} catch (e) {
									this.alertMsg(this.translationService.getByKey("catalog-view.downloadError"));
								}
							}
						}
					}
				})
			} else {
				this.alertMsg(downloadContentUrl);
			}
		} else {
			this.alertMsg(serviceText);
		}
	}

	private alertMsg(errorMsg: string) {
		this.alertService.sendMessage(this.viewTypeService.getPositionType(), ALERT_DISPLAY_TYPES.WARNING, errorMsg)
	}

	public get openDialog() {
		return CadenasDownloadService.openDialog;
	}



	public initDownload(cadenas: CadenasDto, data: ICadenas, attachment: ViewGroupItemDto, catalogKey: string, catalogItemGuid: string) {
		CadenasDownloadService.cadenasData = data;
		CadenasDownloadService.cadenas = cadenas;
		CadenasDownloadService.attachment = attachment;
		CadenasDownloadService.catalogKey = catalogKey;
		CadenasDownloadService.catalogItemGuid = catalogItemGuid;
	}

	private getCadenasLanguage(language: string) {
		switch (language) {
			case 'de': return 'german';
			case 'en': return 'english';
		}
	}

}
