import { EventEmitter, Injectable } from '@angular/core';
import { isInIframe } from '@app/helpers/iframe-helper';
import { loginLanguage } from '@commonConfig/msalb2c.config';
import { isNullish } from '@commonHelpers/math-utils';
import { AuthenticationResult } from '@commonNodeModules/@azure/msal-browser';
import { MsalB2CService } from "@commonServices/msalb2c.service";
import { RequestService } from '@commonServices/request.service';
import { environment } from '@environments/environment';
import { AccountAuthorizationDisplayInfoDto, AccountLanguage, Client as AccountPublicAPI, RolesDto, UserMenuDisplayInfoDto } from '@interfaces/HttpClient/AccountApiPublicModels';
import { Observable, firstValueFrom, interval, takeWhile, timer } from 'rxjs';
import { GlobalVarService } from './global-var.service';

@Injectable({
	providedIn: 'root'
})
export class AccountService {

	constructor(
		private accountPublicApi: AccountPublicAPI,
		private requestService: RequestService,
		private msalB2CService: MsalB2CService,
		private globalVarService: GlobalVarService
	) { }

	private static startInvokeIframe = new EventEmitter();
	private static endInvokeIframe = new EventEmitter();
	private newWindowAllreadyClosed = false;

	async getUserAuthorizationDisplayInfo(): Promise<AccountAuthorizationDisplayInfoDto> {
		try {
			return await firstValueFrom(this.accountPublicApi.authorization_GetAccountAuthorizationDisplayInfo());
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	async getUserMenuDisplayInfo(): Promise<UserMenuDisplayInfoDto> {
		try {
			return await firstValueFrom(this.accountPublicApi.userMenu_GetUserMenuDisplayInfos());
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	async getUserMenuDisplayInfoByCatalogKey(catalogKey: string): Promise<UserMenuDisplayInfoDto> {
		try {
			return await firstValueFrom(this.accountPublicApi.userMenu_GetUserMenuDisplayInfosByCatalogKey(catalogKey));
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	async getUserEffectiveRoles(): Promise<RolesDto> {
		try {
			return await firstValueFrom(this.accountPublicApi.authorization_GetEffectiveAccountRoles());
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	async getLanguage(): Promise<AccountLanguage> {
		try {
			return await firstValueFrom(this.accountPublicApi.userMenu_GetUserLanguage());
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	async getNeedsAuthentication(): Promise<boolean> {
		try {
			return await firstValueFrom(this.accountPublicApi.authorization_GetNeedsAuthentication());
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}

	public async getUniqueId(): Promise<string> {
		return this.msalB2CService.getAllAccounts()[0]?.localAccountId;
	}

	public getEmail(): string {
		return this.msalB2CService.getAllAccounts()[0]?.username;
	}

	public getAccount() {
		return this.msalB2CService.getAllAccounts()[0];
	}

	public isLoggedIn() {
		const account = this.msalB2CService.getAllAccounts();
		return account !== null && account !== undefined && account.length > 0;

	}

	public async login() {
		if (isInIframe()) {
			this.iframeHandling("login");
		} else {
			await this.msalB2CService.login(loginLanguage());
		}


	}

	public async signup() {
		if (isInIframe()) {
			this.iframeHandling("signup");
		} else {
			await this.msalB2CService.signup(loginLanguage());
		}
	}

	public async editUserProfile() {
		if (isInIframe()) {
			this.iframeHandling("edit");
		} else {
			await this.msalB2CService.editUserProfile(loginLanguage());
		}
	}

	public async resetPwd() {
		if (isInIframe()) {
			this.iframeHandling("resetPwd");
		} else {
			await this.msalB2CService.resetPwd(loginLanguage());
		}

	}

	public async acquireIdTokenSilent() {
		await this.msalB2CService.acquireIdTokenSilent();
	}

	public async logout() {
		this.globalVarService.deleteWatchlist();
		if (isInIframe()) {
			this.iframeHandling("logout", true);
		} else {
			await this.msalB2CService.logout();
		}
	}

	public isLoggedInByAcquireTokenSilent(): Promise<AuthenticationResult> {
		return this.msalB2CService.isLoggedInByAcquireTokenSilent();
	}


	private iframeHandling(iframeRedirectQueryParam: string, isLogout = false) {
		AccountService.startInvokeIframe.emit()
		const append = iframeRedirectQueryParam === 'login' ? `&source=${this.globalVarService.getSession()?.source}` : '';
		const newWindow = window.open(`/initintegration/account-handler?handler=${iframeRedirectQueryParam}${append}`, '_blank');
		if (isLogout) {
			Object.keys(localStorage).filter(key => !['ccm_consent', 'technicalKeys', 'ade_user', 'ade_session'].includes(key)).forEach(key => {
				localStorage.removeItem(key);
			})

			timer(2000).subscribe(() => {
				AccountService.endInvokeIframe.emit()
				newWindow?.close();
			})

		} else {
			if (newWindow !== null) {
				newWindow.onbeforeunload = e => {
					this.newWindowAllreadyClosed = false;
					interval(1000).pipe(takeWhile(() => !this.newWindowAllreadyClosed)).subscribe(() => {
						try {
							if (newWindow.location.origin === window.location.origin && newWindow.location.pathname === '/initintegration/account-handler') {
								this.fillLocalStorageToIframe(newWindow);
							}
						} catch {

						}

					})
				}
				newWindow.onmessage = e => {
					if (e.data === 'refreshLogin') {
						this.fillLocalStorageToIframe(newWindow);
					}
				}
			}

		}
	}

	private fillLocalStorageToIframe(newWindow: Window) {
		// Hack um innerhalb des iframes zu Prüfen ob localstorageeintrag wirklich schon übernommen wurde
		// Prüfen bei msal update

		if (Object.keys(newWindow.localStorage).some(item => item.includes(environment.msal.tenant))) {
			if (!isNullish(newWindow.localStorage)) {
				Object.keys(newWindow.localStorage).filter(key => !['ccm_consent', 'technicalKeys', 'ade_user', 'ade_session'].includes(key)).forEach(key => {
					localStorage.setItem(key, newWindow.localStorage[key])
				})
			}
			AccountService.endInvokeIframe.emit()
			this.newWindowAllreadyClosed = true;
			newWindow.close();
		}

	}

	public refreshStartInvokeIframe(): Observable<void> {
		return AccountService.startInvokeIframe;
	}

	public refreshEndInvokeIframe(): Observable<void> {
		return AccountService.endInvokeIframe;
	}


}
