@if (copyToClipboardIsUsable && loadContextMenu) {
	@defer {
		<app-context-menu [attachment]="attachment" [hasMarkedText]="hasMarkedText" />
	}
}

<div class="d-flex flex-column h-100" (keyup)="onKeyUp($event)" tabindex="-1" style="outline: none" appContextMenuOpen>
	<div class="d-flex" [class.overflow-hidden]="currentViewType === viewType.Desktop">
		<div
			id="scrollcontainer"
			[ngClass]="currentViewType === viewType.Desktop ? 'scrollbox-autohover mheight-100' : ''"
			class="w-100 mb-4">
			<div [class.scrollbox-content-autohover]="currentViewType === viewType.Desktop">
				<div class="flex-grow-0 d-flex flex-column">
					@if (attachment?.title) {
						<app-title-viewer [inputTitle]="attachment?.title"> </app-title-viewer>
					}
					<!--Anzeige Bilder im Mobile modus-->
					@if (currentViewType !== viewType.Desktop && imageAttachment && imageAttachment.length > 0) {
						<ul class="img-list mobile p-3 bg-gray-300 d-flex align-items-center justify-content-center">
							<ng-template *ngTemplateOutlet="imgList"></ng-template>
						</ul>
					}
				</div>
				<div>
					<app-htmlviewer [attachment]="attachment"></app-htmlviewer>
				</div>
			</div>
		</div>
		<!--Anzeige Bilder im Desktop modus-->
		@if (currentViewType === viewType.Desktop && imageAttachment && imageAttachment.length > 0) {
			<div class="ms-2 image-min-width img-box pt-3 scrollbox-autohover mheight-100">
				<div class="w-100 scrollbox-content-autohover">
					<div class="bg-gray-300 content-img-width">
						<div class="title px-4 py-3 fw-bold text-larger">
							{{ 'catalog-view.images' | translate }}
						</div>
						<ul class="img-list px-4 pb-4 d-flex flex-column align-items-center justify-content-start">
							<ng-template *ngTemplateOutlet="imgList"></ng-template>
						</ul>
					</div>
				</div>
			</div>
		}
	</div>
	<div class="flex-grow-1">
		@if (attachment?.additionalInfos) {
			<app-additionalinfoviewer [additionalInfoDtos]="attachment?.additionalInfos" [catalogViewShowPrice]="catalogViewShowPrice">
			</app-additionalinfoviewer>
		}
	</div>
</div>

<ng-template #imgList>
	@for (imgAttachment of imageAttachment; track imgAttachment) {
		<li>
			<a
				class="d-flex bg-white justify-content-center align-items-center img-link"
				[routerLink]="getImageUrl(imgAttachment.id)"
				[queryParams]="{ tab: getImgTab(imgAttachment.viewGroup) }"
				queryParamsHandling="merge">
				<img
					loading="eager"
					[src]="imgAttachment.displayUrl | safeUrl"
					decoding="async"
					fetchpriority="high"
					onError="this.src='assets/material/no_photo.svg'"
					[alt]="'img ' + imgAttachment.title" />
			</a>
		</li>
	}
</ng-template>
