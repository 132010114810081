import { Injectable } from '@angular/core';
import { RequestService } from '@commonServices/request.service';
import { Client as CatalogPublicAPI } from "@interfaces/HttpClient/CatalogApiPublicModels";

@Injectable({
	providedIn: 'root'
})
export class CatalogDownloadService {

	constructor(private catalogPublicApi: CatalogPublicAPI, private requestService: RequestService) { }

	async getAttachmentDownloadUri(id: number): Promise<string> {
		try {
			return await this.catalogPublicApi.download_GetAttachmentDownloadUri(id).toPromise()
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: 204, return: undefined }
			]);
		}
	}
}
