import { ChangeDetectionStrategy, Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { NgbDropdown, NgbDropdownMenu, NgbDropdownToggle } from "@commonNodeModules/@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from '@commonNodeModules/@ngx-translate/core';
import { environment } from '@environments/environment';

@Component({
	selector: 'app-help-public',
	templateUrl: './help-public.component.html',
	styleUrls: ['./help-public.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	standalone: true,
	imports: [NgbDropdown, NgbDropdownToggle, NgbDropdownMenu, TranslateModule]
})
export class HelpPublicComponent implements OnInit {

	@ViewChild("helpDropdown") helpDropdown: NgbDropdown;

	constructor() { }

	ngOnInit(): void {
	}

	public onHoverDropdown() {
		this.helpDropdown.open()
	}

	public onLeaveDropdown() {
		this.helpDropdown.close()
	}

	public get helpdeskUrl() {
		return `${environment.helpdesk}/Solution/ADE_BESUCHER_FAQ/Content/Faq-2_35621`;
	}
}