import { HostListener, Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { AccountService } from './account.service';

@Injectable({
	providedIn: 'root'
})
export class AuthenticatedUserService {
	private authenticatedUserId = new BehaviorSubject<string>(null)
	private currentAuthenticatedUserId: string;
	private authenticatedUserServiceSubscription: Subscription
	constructor(private accountService: AccountService) {
	}


	public init() {
		this.updateFromAccountService()
		this.authenticatedUserServiceSubscription = this.getAsObservable().subscribe(authenticatedUserId => this.currentAuthenticatedUserId = authenticatedUserId)
	}

	getAsObservable() {
		return this.authenticatedUserId.asObservable();
	}

	private updateFromAccountService(): void {
		const account = this.accountService.getAccount();
		this.authenticatedUserId.next(account?.localAccountId)
	}

	public getAuthenticatedUserId() {
		return this.currentAuthenticatedUserId
	}

	@HostListener('window:beforeunload')
	destroy(): void {
		this.authenticatedUserServiceSubscription?.unsubscribe();
	}
}