import { isNullish } from "@commonHelpers/math-utils";

export const enterFullscreen = async (element: Element) => {
	if (element.requestFullscreen) {
		await element.requestFullscreen();
	} else if ((element as any).webkitRequestFullscreen) {
		await (element as any).webkitRequestFullscreen();
	}
}

export const exitFullscreen = async () => {
	if (document.exitFullscreen) {
		await document.exitFullscreen();
	} else if ((document as any).webkitExitFullscreen) {
		await (document as any).webkitExitFullscreen();
	}
}

export const toggleFullscreen = async (element: Element) => {
	if (isFullscreen()) {
		await exitFullscreen()
	} else {
		await enterFullscreen(element)
	}
}

export const isFullscreen = () => !isNullish(document.fullscreenElement) || !isNullish((document as any).webkitFullscreenElement);

export const isFullscreenEnabled = () => document.fullscreenEnabled || (document as any).webkitFullscreenEnabled;;
