import { Pipe, PipeTransform } from "@angular/core";
import { isNullish } from "@commonHelpers/math-utils";
import { localeNumberFormat } from "@commonHelpers/number-formater";


@Pipe({
	name: "numberFormat",
	standalone: true
})
export class NumberFormatPipe implements PipeTransform {
	constructor() { }
	transform(value?: number | string) {
		return isNullish(value) ? "" : localeNumberFormat(value);
	}
}
