import { Injectable } from '@angular/core';
import { isNullish } from '@commonHelpers/math-utils';
import { globalEnvironment } from '@environments/globalEnvironment';
import { interval, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class IntervalService {
	private functionEmit = new Subject<any>();
	private worker: Worker
	constructor() {
	}

	public getInterval(): Subject<any> {

		if (!isNullish(this.worker)) {
			try {
				this.worker.onmessage = () => {
					this.functionEmit.next(null)
				};
				return this.functionEmit
			} catch (e) {
				interval(globalEnvironment.trackIntervalInSec * 1000).subscribe(() => this.functionEmit.next(null))
			}

		} else {
			// Web Workers are not supported in this environment.
			// You should add a fallback so that your program still executes correctly.
		}
	}

	public init() {
		if (typeof Worker !== 'undefined') {
			// Create a new
			this.worker = new Worker(new URL('./../webworkers/interval.worker', import.meta.url));
			this.worker.postMessage({ initTimer: globalEnvironment.trackIntervalInSec * 1000 });
		} else {
			// Web Workers are not supported in this environment.
			// You should add a fallback so that your program still executes correctly.
		}
	}

}



export interface IntervalMsg {
	isRunning: boolean;
	type: IntervalType;
}

export enum IntervalType {
	ServiceWorker = 1,
	DefaultSearchbar = 2
}