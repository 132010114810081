<footer
	id="mobileBarFooter"
	class="bg-gray-200 w-100 d-flex align-items-center justify-content-between mobile-bar border-gray-400 border-top">
	<div class="d-flex gap-2 ps-3">
		<ng-container *ngTemplateOutlet="buttonsTemplate; context: { fit: true }"> </ng-container>
	</div>
	@if (showMoreButton) {
		<div class="rounded position-relative m-1" [class.bg-primary]="showMoreButtons">
			<div [class] class="show-more-img mask-settings bg-dark button-size border" (click)="showMoreButtons = !showMoreButtons"></div>
			@if (showMoreButtons) {
				<div class="position-absolute bg-gray-200 p-1 border d-flex button-position">
					<ng-container *ngTemplateOutlet="buttonsTemplate; context: { fit: false }"> </ng-container>
				</div>
			}
		</div>
	}
</footer>

<ng-template #buttonsTemplate let-fit="fit">
	@for (button of viewMobileBarButtons; track button) {
		@if ((!fit && !button.fitsInMobileBar) || (fit && button.fitsInMobileBar)) {
			@if (!button.disabled) {
				<a
					[routerLink]="button.routerLink"
					(click)="buttonClicked(button.type)"
					[attr.aria-label]="mobileButtonType[button.type]"
					[queryParams]="button.queryParams"
					[queryParamsHandling]="button.queryParamsHandling"
					class="rounded"
					[class.bg-primary]="activeType === button.type">
					<div class="{{ button.maskImg }} mask-settings bg-dark button-size border mobile-button"></div>
				</a>
			}
			@if (button.disabled) {
				<div class="rounded">
					<div class="{{ button.maskImg }} mask-settings bg-gray-400 button-size border mobile-button"></div>
				</div>
			}
		}
	}
</ng-template>
