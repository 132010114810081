// eslint-disable-next-line max-classes-per-file
import { ValidationMode } from "@enums/validation-mode";
import { CatalogExternalViewType, CatalogListingSource, CatalogSuggestionSource, CatalogViewOrigin, ExportSource, FormatId, LoginSource, ProfessionGroupType, SearchOrigin, SearchSuggestionSource, ShareCatalogItemType, TrackAttachmentDto, TrackCatalogDto, TrackCatalogExternalDto, TrackCatalogItemDto, TrackCatalogListingDto, TrackCatalogSuggestionDto, TrackContactFormOpenDto, TrackContactFormSubmitDto, TrackExportDto, TrackLoginRequestDto, TrackLoginSuccessDto, TrackSearchCatalogDto, TrackSearchGlobalDto, TrackSearchRelatedBaseDto, TrackSearchResultRelatedBaseDto, TrackShareCatalogItemDto } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";
import { ContactFormType } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { TrackBaseDtoClass } from "./track-base-dto";
import { ContactFormIntentions } from "../../interfaces/HttpClient/AnalyticsApiTrackingModels";


export class TrackSearchRelatedBaseDtoClass extends TrackBaseDtoClass implements TrackSearchRelatedBaseDto {
	searchGuid?: string | undefined;

	constructor(params: TrackSearchRelatedBaseDto) {
		super(params);
		this.searchGuid = params.searchGuid;
	}
}

export class TrackSearchResultRelatedBaseDtoClass extends TrackSearchRelatedBaseDtoClass implements TrackSearchResultRelatedBaseDto {
	searchResultPage?: number | undefined;
	searchResultPosition?: number | undefined;

	constructor(params: TrackSearchResultRelatedBaseDto) {
		super(params);
		this.searchResultPage = params.searchResultPage;
		this.searchResultPosition = params.searchResultPosition;
		this.validationEntries.push(['searchResultPage', [ValidationMode.NotLowerZero]])
		this.validationEntries.push(['searchResultPosition', [ValidationMode.NotLowerZero]])
	}
}

export class TrackExportDtoClass extends TrackBaseDtoClass implements TrackExportDto {
	catalogKey?: string | undefined;
	catalogItemGuid?: string;
	attachmentId?: number | undefined;
	exportFormat?: FormatId;
	source?: ExportSource;

	constructor(params: TrackExportDto) {
		super(params);
		this.catalogKey = params.catalogKey;
		this.catalogItemGuid = params.catalogItemGuid;
		this.attachmentId = params.attachmentId;
		this.exportFormat = params.exportFormat;
		this.source = params.source;
		this.validationEntries.push(['catalogKey', [ValidationMode.NotEmptyOrNullish]])
		this.validationEntries.push(['catalogItemGuid', [ValidationMode.NotEmptyOrNullish, ValidationMode.Guid]])
		this.validationEntries.push(['attachmentId', [ValidationMode.NotLowerOne]])
		this.validationEntries.push(['exportFormat', [ValidationMode.NotLowerOne]])
		this.validationEntries.push(['source', [ValidationMode.NotLowerZero, ValidationMode.NotNullish]])
		this.validate();
	}

}

export class TrackLoginRequestDtoClass extends TrackBaseDtoClass implements TrackLoginRequestDto {
	source?: LoginSource;
	catalogKey?: string | undefined;
	catalogItemGuid?: string | undefined;
	attachmentId?: number | undefined;
	viewMode?: string | undefined;

	constructor(params: TrackLoginRequestDto) {
		super(params);
		this.source = params.source;
		this.catalogKey = params.catalogKey;
		this.catalogItemGuid = params.catalogItemGuid;
		this.attachmentId = params.attachmentId;
		this.viewMode = params.viewMode;
		this.validationEntries.push(['source', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validationEntries.push(['catalogItemGuid', [ValidationMode.Guid]]);
		this.validationEntries.push(['attachmentId', [ValidationMode.NotLowerOne]]);
		this.validate();
	}
}

export class TrackLoginSuccessDtoClass extends TrackBaseDtoClass implements TrackLoginSuccessDto {
	catalogKey?: string | undefined;
	professionGroup?: ProfessionGroupType;
	source?: LoginSource;

	constructor(params: TrackLoginSuccessDto) {
		super(params);
		this.catalogKey = params.catalogKey;
		this.professionGroup = params.professionGroup;
		this.source = params.source;
		this.validationEntries.push(['source', [ValidationMode.NotLowerZero]]);
		this.validationEntries.push(['professionGroup', [ValidationMode.NotLowerOne]]);
		this.validate();
	}
}

export class TrackCatalogListingDtoClass extends TrackBaseDtoClass implements TrackCatalogListingDto {
	catalogKeys?: string | undefined;
	source?: CatalogListingSource;
	searchGuid?: string;
	constructor(params: TrackCatalogListingDto) {
		super(params);
		this.catalogKeys = params.catalogKeys;
		this.source = params.source;
		this.searchGuid = params.searchGuid
		this.validationEntries.push(['catalogKeys', [ValidationMode.NotNullish, ValidationMode.NotListEmpty]]);
		this.validationEntries.push(['source', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validate();
	}
}

export class TrackCatalogSuggestionDtoClass extends TrackBaseDtoClass implements TrackCatalogSuggestionDto {
	catalogKeys?: string | undefined;
	source?: CatalogSuggestionSource;

	constructor(params: TrackCatalogSuggestionDto) {
		super(params);
		this.catalogKeys = params.catalogKeys;
		this.source = params.source;
		this.validationEntries.push(['catalogKeys', [ValidationMode.NotNullish, ValidationMode.NotListEmpty]]);
		this.validationEntries.push(['source', [ValidationMode.NotLowerZero]]);
		this.validate();
	}
}

export class TrackCatalogDtoClass extends TrackSearchResultRelatedBaseDtoClass implements TrackCatalogDto {
	catalogKey?: string | undefined;
	isCatalogInformation?: boolean;
	catalogViewOrigin?: CatalogViewOrigin;

	constructor(params: TrackCatalogDto) {
		super(params);
		this.catalogKey = params.catalogKey;
		this.isCatalogInformation = params.isCatalogInformation;
		this.catalogViewOrigin = params.catalogViewOrigin;
		this.validationEntries.push(['catalogKey', [ValidationMode.NotEmptyOrNullish]]);
		this.validationEntries.push(['isCatalogInformation', [ValidationMode.NotNullish]]);
		this.validationEntries.push(['catalogViewOrigin', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validate();
	}

}

export class TrackCatalogItemDtoClass extends TrackSearchResultRelatedBaseDtoClass implements TrackCatalogItemDto {
	catalogKey?: string | undefined;
	catalogItemGuid?: string;
	catalogViewOrigin?: CatalogViewOrigin;

	constructor(params: TrackCatalogItemDto) {
		super(params);
		this.catalogKey = params.catalogKey;
		this.catalogItemGuid = params.catalogItemGuid;
		this.catalogViewOrigin = params.catalogViewOrigin;
		this.validationEntries.push(['catalogKey', [ValidationMode.NotEmptyOrNullish]]);
		this.validationEntries.push(['catalogItemGuid', [ValidationMode.NotEmptyOrNullish, ValidationMode.Guid]]);
		this.validationEntries.push(['catalogViewOrigin', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validate();
	}
}

export class TrackAttachmentDtoClass extends TrackBaseDtoClass implements TrackAttachmentDto {
	catalogKey?: string | undefined;
	catalogItemGuid?: string;
	attachmentId?: number | undefined;
	isExplicitlyViewed?: boolean;
	isLargeViewer?: boolean;

	constructor(params: TrackAttachmentDto) {
		super(params);
		this.catalogKey = params.catalogKey;
		this.catalogItemGuid = params.catalogItemGuid;
		this.attachmentId = params.attachmentId;
		this.isExplicitlyViewed = params.isExplicitlyViewed;
		this.isLargeViewer = params.isLargeViewer;
		this.validationEntries.push(['catalogKey', [ValidationMode.NotEmptyOrNullish]]);
		this.validationEntries.push(['catalogItemGuid', [ValidationMode.NotEmptyOrNullish, ValidationMode.Guid]]);
		this.validationEntries.push(['attachmentId', [ValidationMode.NotNullish, ValidationMode.NotLowerOne]]);
		this.validationEntries.push(['isExplicitlyViewed', [ValidationMode.NotNullish]]);
		this.validationEntries.push(['isLargeViewer', [ValidationMode.NotNullish]]);
		this.validate();
	}
}

export class TrackSearchGlobalDtoClass extends TrackSearchRelatedBaseDtoClass implements TrackSearchGlobalDto {
	origin?: SearchOrigin;
	suggestionSource?: SearchSuggestionSource;
	searchText?: string | undefined;
	searchFilter?: string | undefined;
	pageIndex?: number;
	pageSize?: number;
	countCatalogs?: number;
	countCatalogItems?: number;

	constructor(params: TrackSearchGlobalDto) {
		super(params);
		this.origin = params.origin;
		this.suggestionSource = params.suggestionSource;
		this.searchText = params.searchText;
		this.searchFilter = params.searchFilter;
		this.pageIndex = params.pageIndex;
		this.pageSize = params.pageSize;
		this.countCatalogs = params.countCatalogs;
		this.countCatalogItems = params.countCatalogItems;
		this.validationEntries.push(['origin', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validationEntries.push(['pageIndex', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validationEntries.push(['pageIndex', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validationEntries.push(['countCatalogs', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validationEntries.push(['countCatalogItems', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validate();
	}
}

export class TrackSearchCatalogDtoClass extends TrackSearchRelatedBaseDtoClass implements TrackSearchCatalogDto {
	origin?: SearchOrigin;
	suggestionSource?: SearchSuggestionSource;
	catalogKey?: string | undefined;
	searchText?: string | undefined;
	searchFilter?: string | undefined;
	pageIndex?: number;
	pageSize?: number;
	countCatalogItems?: number;

	constructor(params: TrackSearchCatalogDto) {
		super(params);
		this.origin = params.origin;
		this.suggestionSource = params.suggestionSource;
		this.catalogKey = params.catalogKey;
		this.searchText = params.searchText;
		this.searchFilter = params.searchFilter;
		this.pageIndex = params.pageIndex;
		this.pageSize = params.pageSize;
		this.countCatalogItems = params.countCatalogItems;
		this.validationEntries.push(['origin', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validationEntries.push(['suggestionSource', [ValidationMode.NotLowerZero]]);
		this.validationEntries.push(['catalogKey', [ValidationMode.NotEmptyOrNullish]]);
		this.validationEntries.push(['pageIndex', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validationEntries.push(['pageSize', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validationEntries.push(['countCatalogItems', [ValidationMode.NotNullish, ValidationMode.NotLowerZero]]);
		this.validate();
	}
}



export class TrackContactFormOpenDtoClass extends TrackSearchRelatedBaseDtoClass implements TrackContactFormOpenDto {
	catalogKey?: string | undefined;
	catalogItemGuid?: string | undefined;
	contactFormType: ContactFormType;

	constructor(params: TrackContactFormOpenDto) {
		super(params);
		this.catalogKey = params.catalogKey;
		this.catalogItemGuid = params.catalogItemGuid;
		this.contactFormType = params.contactFormType;
		this.validationEntries.push(['catalogItemGuid', [ValidationMode.Guid]]);
		this.validate();
	}
}

export class TrackContactFormSubmitDtoClass extends TrackSearchRelatedBaseDtoClass implements TrackContactFormSubmitDto {
	catalogKey?: string | undefined;
	catalogItemGuid?: string | undefined;
	salutation?: string | undefined;
	firstName?: string | undefined;
	lastName?: string | undefined;
	phone?: string | undefined;
	companyName?: string | undefined;
	message?: string | undefined;
	professionGroup?: number;
	intentions?: number[] | undefined;
	subject: string;
	contactFormType: ContactFormType;

	constructor(params: TrackContactFormSubmitDto) {
		super(params);
		this.catalogKey = params.catalogKey;
		this.catalogItemGuid = params.catalogItemGuid;
		this.salutation = params.salutation;
		this.firstName = params.firstName;
		this.lastName = params.lastName;
		this.phone = params.phone;
		this.companyName = params.companyName;
		this.message = params.message;
		this.subject = params.subject;
		this.professionGroup = params.professionGroup;
		this.intentions = params.intentions;
		this.contactFormType = params.contactFormType;
		this.validationEntries.push(['catalogItemGuid', [ValidationMode.Guid]]);
		if (this.intentions?.includes(ContactFormIntentions.CallBack)) {
			this.validationEntries.push(['phone', [ValidationMode.NotEmptyOrNullish]]);
		}
		this.validate();
	}
}

export class TrackShareCatalogItemDtoClass extends TrackBaseDtoClass implements TrackShareCatalogItemDto {
	catalogKey?: string | undefined;
	catalogItemGuid?: string | undefined;
	shareCatalogItemType?: ShareCatalogItemType;

	constructor(params: TrackShareCatalogItemDto) {
		super(params);
		this.catalogKey = params.catalogKey;
		this.catalogItemGuid = params.catalogItemGuid;
		this.shareCatalogItemType = params.shareCatalogItemType;
		this.validationEntries.push(['catalogKey', [ValidationMode.NotEmptyOrNullish]]);
		// this.validationEntries.push(['message', [ValidationMode.NotEmptyOrNullish]]);
		this.validationEntries.push(['shareCatalogItemType', [ValidationMode.NotLowerZero, ValidationMode.NotNullish]]);
		this.validate();
	}
}

export class TrackCatalogExternalDtoClass extends TrackBaseDtoClass implements TrackCatalogExternalDto {
	catalogKey?: string | undefined;
	type?: CatalogExternalViewType;
	targetUrl?: string | undefined;

	constructor(params: TrackCatalogExternalDto) {
		super(params);
		this.catalogKey = params.catalogKey;
		this.type = params.type;
		this.targetUrl = params.targetUrl;
		this.validationEntries.push(['catalogKey', [ValidationMode.NotEmptyOrNullish]]);
		this.validationEntries.push(['type', [ValidationMode.NotLowerZero, ValidationMode.NotNullish]]);
		this.validationEntries.push(['targetUrl', [ValidationMode.NotEmptyOrNullish]]);
		this.validate();
	}
}
