import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

@Component({
	selector: 'lib-loading',
	templateUrl: './loading.component.html',
	styleUrls: ['./loading.component.scss'],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [NgClass]
})
export class LoadingComponent implements OnInit {
	@Input() color = 'primary';
	@Input() size = 2;
	constructor() { }

	ngOnInit(): void {
	}

	public get colorClass() {
		return `text-${this.color}`
	}

}
