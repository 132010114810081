import { HttpStatusCode } from "@angular/common/http";
import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class RequestService {
	constructor() { }

	public errorHandling(
		e: any,
		defaultErrorReturn: any,
		specificCodes?: { status: HttpStatusCode; return: any }[]
	) {
		if (specificCodes && specificCodes.some(s => s.status === e.status)) {
			const returnValue = specificCodes.find(f => f.status === e.status)?.return
			return returnValue instanceof Function ? returnValue() : returnValue
		} else {
			return defaultErrorReturn;
		}
	}
}
