import { Injectable } from '@angular/core';
import { CatalogViewRelatedCatalogsDto, NotificationLanguage } from '@interfaces/HttpClient/CatalogApiPublicModels';
import { SearchFilterEntryDto, SearchingType } from '@interfaces/HttpClient/SearchApiModels';
import { Observable, Subject } from 'rxjs';

@Injectable({
	providedIn: 'root'
})
export class NoResultService {

	constructor(
	) { }
	private subject = new Subject<INoResult>();

	private sendMailSubject = new Subject();

	public open(noresult: INoResult) {
		this.subject.next(
			noresult
		);
	}

	public sendMail() {
		this.sendMailSubject.next(null);
	}

	public getSendMailSubscription() {
		return this.sendMailSubject.asObservable();
	}

	public getNoResultDialog(): Observable<INoResult> {
		return this.subject.asObservable();
	}
}
export interface INoResult {
	searchTerm: string,
	centered: boolean,
	catalogKey?: string,
	disableGlobalSearch?: boolean,
	catalogName?: string,
	language?: NotificationLanguage,
	catalogHits?: SearchFilterEntryDto[],
	isPreview?: boolean,
	relatedCatalogs?: CatalogViewRelatedCatalogsDto[],
	searchFilter?: string,
	searchingType: SearchingType
}
