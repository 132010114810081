import { IEnvironment } from "@interfaces/iEnvironment";

export const environment: IEnvironment = {
	production: true,
	catalogApi: "https://catalog.ausschreiben.de",
	analyticsApi: "https://atics.ausschreiben.de",
	protocolApi: "https://protocol.ausschreiben.de",
	importApi: "https://import.ausschreiben.de",
	exportApi: "https://exportservice.ausschreiben.de",
	searchApi: "https://searchservice.ausschreiben.de",
	catalogManagerUrl: "https://prod.catalogmanager.orcalab.de",
	webApi: "https://ausschreiben.de",
	consentApi: "https://consent.ausschreiben.de",
	blobUrl: "https://adeprod0sa.blob.core.windows.net",
	naturalLanguageProcessingApi: "https://nlp.ausschreiben.de",
	accountApi: "https://account.ausschreiben.de",
	ftpApi: "https://ftp.ausschreiben.de",
	helpdesk: "https://helpdesk.orca-software.com",
	catalogOfferRequestUrl: "https://info.ausschreiben.de/fuer-produkthersteller/angebot-paket-erweiterungen",
	analyticsOfferRequestUrl: "https://info.ausschreiben.de/fuer-produkthersteller/angebot-paket-erweiterungen",
	integrationOfferRequestUrl: "https://info.ausschreiben.de/fuer-produkthersteller/angebot-paket-erweiterungen",
	infoAdeUrl: "https://info.ausschreiben.de",
	infoletterUrl: "https://info.ausschreiben.de/wissen/infoletter-fuer-planer/?utm_source=orca&utm_medium=ade&utm_campaign=nlade",
	infoIntegrationUrl: "https://info.ausschreiben.de/fuer-produkthersteller/katalog-integration",
	aboutUsUrl: "https://info.ausschreiben.de/ueber-uns",
	aboutUsGaebUrl: "https://info.ausschreiben.de/ueber-uns/gaeb",
	contactUrl: "https://info.ausschreiben.de/ueber-uns/kontakt",
	mediaDataUrl: "https://info.ausschreiben.de/fuer-produkthersteller/mediadaten",
	manufacturerUrl: "https://info.ausschreiben.de/fuer-produkthersteller",
	objectConsultantUrl: "https://info.ausschreiben.de/fuer-objektberater",
	planerUrl: "https://info.ausschreiben.de/fuer-planer",
	softwareUrl: "https://info.ausschreiben.de/fuer-softwarehersteller",
	helpdeskFaqUrl: "https://helpdesk.orca-software.com/Solution/Produkthersteller/Content/intro-2_25399",
	newCustomerUrl: "https://info.ausschreiben.de/fuer-produkthersteller?utm_source=orca&utm_medium=ade&utm_campaign=PH-Akquise",
	supportRequestUrl: "https://helpdesk.orca-software.com/support/external/?product=Produkthersteller",
	alertInvalidTrackingValues: false,
	msal: {
		clientId: "14c9059a-9e0d-446b-a8b6-628742699ce2",
		middlewareAppIdUriSuffix: "adeprodmiddleware",
		tenant: "orcaidentity",
		signInFlow: "B2C_1A_Signup_signin",
		signUpFlow: "B2C_1A_SignUp",
		passwordResetFlow: "B2C_1A_PasswordReset",
		profileEditFlow: "B2C_1A_ProfileEdit",
		apiAccessScope: "user_impersonation"
	},
	googleTagManager: {
		id: "GTM-TJL4C84",
		gtmAuth: "LfIooTW0Krd35nulH-2bvw",
		gtmPreview: "env-2",
		gtmResourcePath: ""
	},
	ccm19Url: "https://consent.ausschreiben.de/ccm/app.js?apiKey=14176526217ccf59b9ecab96615d70f0711d28cc70fdf478&domain=9e1d037",
	cadenas: {
		apiKey: "19c42f08e6d245ef9bb17fc2a252cd71",
		baseUrl: "https://webapi.partcommunity.com",
		userServerType: "SUPPLIER_EXTERNAL_ausschreiben-navigation"
	},
	helpdeskApiKey: "7383ff6173f9d26aa33094551c83d612",
	applicationInsightsSettings: {
		instrumentationKey: "e91978b6-ef26-471b-bd54-d646780d5f34",
	},
	feedbackKey: "BD727055-04D7-47C3-AFEA-FE1699609634",
	needsAdditionalAuthenticationInformation: false,
	portalApi: "https://ausschreiben.de/"
}