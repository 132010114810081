import { ProtectedResourceScopes } from "@azure/msal-angular";
import { InteractionType } from "@azure/msal-browser";
import { GlobalTranslationVar } from "@commonClass/GlobalTranslationVar";
import { Language } from "@commonEnums/language";
import { environment } from "@environments/environment";

export const interactionType = InteractionType.Redirect;
export const baseAuthority = "https://" + environment.msal.tenant + ".b2clogin.com/" + environment.msal.tenant + ".onmicrosoft.com/";
export const signinAuthority = baseAuthority + environment.msal.signInFlow;
export const pwdResetAuthority = baseAuthority + environment.msal.passwordResetFlow;
export const profEditAuthority = baseAuthority + environment.msal.profileEditFlow;
export const scopes = [`https://${environment.msal.tenant}.onmicrosoft.com/${environment.msal.middlewareAppIdUriSuffix}/${environment.msal.apiAccessScope}`];


export const protectedResourceMap = (apiAccesses: string[]): Map<string, (string | ProtectedResourceScopes)[] | null> => {
	const resourceMap = new Map<string, (string | ProtectedResourceScopes)[] | null>();

	for (const apiAccess of apiAccesses) {
		resourceMap.set(apiAccess, scopes);
	}

	return resourceMap

}

export const loginLanguage = () => {
	const onboardingLanguageFromUrl = new URLSearchParams(window.location.search).get("language");
	switch (onboardingLanguageFromUrl) {
		case 'German': return Language.DE
		case 'English': return Language.EN
	}
	return GlobalTranslationVar.SELECTED_LANGUAGE === Language.DE ? Language.DE : Language.EN
};
