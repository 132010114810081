import { Injectable } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { AccountInfo, AuthenticationResult } from "@azure/msal-browser";
import { environment } from "@environments/environment";
import { firstValueFrom } from "rxjs";
import { isNullish } from "../helpers/math-utils";

@Injectable({
	providedIn: 'root'
})
export class MsalB2CService {

	constructor(
		private authService: MsalService
	) { }

	public async signup(loginLanguage: string): Promise<void | AuthenticationResult> {
		if (!isNullish(environment?.msal) && !isNullish(environment?.msal?.signUpFlow)) {
			return this.invokeFlow(environment.msal.signUpFlow, loginLanguage);
		}
		throw new Error('environment not set');
	}

	public async login(loginLanguage: string): Promise<void | AuthenticationResult> {
		if (!isNullish(environment?.msal) && !isNullish(environment?.msal?.signInFlow)) {
			return this.invokeFlow(environment.msal.signInFlow, loginLanguage);
		}
		throw new Error('environment not set');
	}

	public async logout(): Promise<void | AuthenticationResult> {
		return this.invokeFlow('logout', 'logout');
	}

	public async editUserProfile(loginLanguage: string): Promise<void | AuthenticationResult> {
		if (!isNullish(environment?.msal) && !isNullish(environment?.msal?.profileEditFlow)) {
			return this.invokeFlow(environment.msal.profileEditFlow, loginLanguage);
		}
	}

	public async resetPwd(loginLanguage: string): Promise<void | AuthenticationResult> {
		if (!isNullish(environment?.msal) && !isNullish(environment?.msal?.passwordResetFlow)) {
			return this.invokeFlow(environment.msal.passwordResetFlow, loginLanguage);
		}
	}


	public isLoggedIn(): boolean {
		return this.authService.instance.getAllAccounts().length > 0;
	}

	public acquireTokenSilent(scopesUrls: string[]): Promise<AuthenticationResult> {

		const account = this.getAllAccounts()[0];
		return firstValueFrom(this.authService.acquireTokenSilent({ account, scopes: scopesUrls }));
	}


	public async acquireIdTokenSilent(): Promise<AuthenticationResult> {
		if (!isNullish(environment?.msal) && !isNullish(environment?.msal?.clientId)) {
			return this.acquireTokenSilent([environment.msal.clientId]);
		}
		throw new Error('environment not set');

	}

	public async isLoggedInByAcquireTokenSilent() {
		await firstValueFrom(this.authService.initialize());
		await firstValueFrom(this.authService.handleRedirectObservable()); // This will no longer throw this error since initialize completed before this was invoked
		return this.acquireIdTokenSilent();
	}

	public getAllAccounts(): AccountInfo[] {
		return this.authService.instance.getAllAccounts();
	}

	private async invokeFlow(flowName: string, loginlanguage?: string): Promise<void | AuthenticationResult> {

		if (flowName === 'logout') {
			return firstValueFrom(this.authService.logout());
		}
		if (!isNullish(environment?.msal)) {
			const authority = `${"https://" + environment.msal.tenant + ".b2clogin.com/" + environment.msal.tenant + ".onmicrosoft.com/"}${flowName}`;
			return firstValueFrom(this.authService.loginRedirect({
				extraQueryParameters: { ui_locales: loginlanguage ?? "" },
				authority,
				scopes: this.getScopes()
			}));
		} else {
			throw new Error('environment not set');
		}
	}



	private getScopes(): string[] {
		if (!isNullish(environment?.msal)) {
			return [`https://${environment.msal.tenant}.onmicrosoft.com/${environment.msal.middlewareAppIdUriSuffix}/${environment.msal.apiAccessScope}`];
		} else {
			throw new Error('environment not set');
		}
	}


	public async getAccessToken(): Promise<string> {
		try {
			const result = await this.acquireTokenSilent(this.getScopes());

			return result.accessToken;
		}
		catch {
			return '';
		}
	}

}
