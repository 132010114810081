import { HttpStatusCode } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { isNullish } from '@commonHelpers/math-utils';
import { RequestService } from '@commonServices/request.service';
import { Client as AccountPublicAPI, SoftwareIntegrationApiCallbackMode, SoftwareIntegrationApiCallbackType, SoftwareIntegrationApiSessionDto, SoftwareIntegrationApiUiDataDto } from '@interfaces/HttpClient/AccountApiPublicModels';
import { INode } from '@interfaces/iNode';
import { Subject, Subscription, combineLatest, interval } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AuthenticatedUserService } from './authenticated-user.service';
import { GlobalVarService } from './global-var.service';
import { SessionService } from './session.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class SoftwareIntegrationCallbackService implements OnDestroy {

	constructor(
		private globalVarService: GlobalVarService,
		private router: Router,
		private requestService: RequestService,
		private accountPublicApi: AccountPublicAPI,
		private sessionService: SessionService,
		private userService: UserService,
		private authenticatedUserService: AuthenticatedUserService,
	) {
	}

	private currentSession: SoftwareIntegrationApiSessionDto;
	private isCatalog = false;
	private currentUpdateCall: string;
	private currentSessionCall: string;
	private nextSessionCall: Subject<void> = new Subject<void>();
	private msalLoading = new Subject<void>();
	private msalAllreadyLoaded = false;

	private sessionAndUserSubscription: Subscription;
	private currentCatalogGuid: string;
	private currentSendCatalogGuids: string;
	private blockUpdateSession = false;
	private currentCatalogNodes: INode[];
	private currentMarkedNodes: INode[];
	private currentNode: INode;

	ngOnDestroy(): void {
		this.sessionAndUserSubscription?.unsubscribe()
	}

	async startSession() {
		try {
			this.sessionAndUserSubscription = combineLatest([this.sessionService.getAsObservable(), this.userService.getAsObservable(), this.authenticatedUserService.getAsObservable()]).subscribe(async ([session, user, authenticatedUserId]) => {
				const sessionCall = session?.guid + user?.guid + authenticatedUserId;
				if (!isNullish(session) && !isNullish(user) && this.currentSessionCall !== sessionCall) {
					this.currentSessionCall = sessionCall;
					await this.updateSessionUiDataAsync({
						sessionId: session.guid,
						userId: user.guid,
						authenticatedUserId,
						userAgent: window.navigator.userAgent
					})
				}

			})

			const urlSearchParams = new URLSearchParams(window.location.search);
			let sessionId = urlSearchParams.get('sessionId');
			sessionId ??= this.globalVarService.getSoftwareIntegrationSessionId();
			if (sessionId) {
				this.globalVarService.setSoftwareIntegrationSessionId(sessionId);
				this.currentSession = await this.accountPublicApi.softwareIntegrationApi_GetSession(sessionId).toPromise();
				if (!isNullish(this.currentSession?.initialCatalogKey)) {
					if (this.msalAllreadyLoaded) {
						await this.navigateToCatalog();
					} else {
						this.msalLoading.subscribe(async () => {
							await this.navigateToCatalog();
						})
					}
				} else {
					this.isCatalog = false;
					this.locationChanged("startpage");
				}
				this.router.events.pipe(
					filter((event) => event instanceof NavigationEnd)
				).subscribe(async (event: NavigationEnd) => {
					this.currentCatalogGuid = null;
					if (event.url.startsWith('/catalog')) {
						const catalogUrlParts = event.url.split(/\?|\//);
						const idx = catalogUrlParts.indexOf('catalogItem');
						if (idx !== -1) {
							this.currentCatalogGuid = catalogUrlParts[idx + 1];
						}

						if (!this.isCatalog) {
							this.locationChanged("catalog");
						}
						this.isCatalog = true;
					} else {
						this.currentCatalogNodes = null;
						if (this.isCatalog) {
							this.locationChanged("startpage");

						}
						this.isCatalog = false;
					}
				});

				interval(100).subscribe(async () => {
					const checkNodes = this.getCheckNodes();
					if (!this.blockUpdateSession && this.currentSendCatalogGuids !== checkNodes) {
						if (!isNullish(this.currentCatalogGuid) && this.currentNode?.guid !== this.currentCatalogGuid) {
							return;
						}
						this.blockUpdateSession = true;
						if (isNullish(this.currentCatalogGuid)) {
							await this.updateSessionUiSelectionDataAsync();
						} else {
							let markedNodes = this.currentMarkedNodes;
							if (isNullish(markedNodes)) {
								markedNodes = [];
							}
							if (!markedNodes.some(node => node.guid === this.currentCatalogGuid)) {
								markedNodes.push(this.currentNode)
							}
							await this.updateSessionUiSelectionDataAsync(this.currentNode, markedNodes);
						}
						this.currentSendCatalogGuids = checkNodes;
						this.blockUpdateSession = false;
					}

				})
			}


		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HttpStatusCode.NoContent, return: undefined }
			]);
		}
	}

	private getCheckNodes(): string {
		const markedNodes = this.currentMarkedNodes?.map(node => node.guid) ?? [];
		if (!markedNodes.some(node => node === this.currentCatalogGuid)) {
			markedNodes.push(this.currentCatalogGuid)
		}
		return this.currentCatalogGuid + ',' + markedNodes.sort((a, b) => a?.localeCompare(b)).join(',') ?? ''
	}

	public setMarkedNodes(markedNodes: INode[]) {
		this.currentMarkedNodes = markedNodes;
	}

	public setCurrentNode(guid: string, id: number) {
		this.currentNode = {
			id, guid
		}
	}

	private async navigateToCatalog() {
		await this.router.navigate(['/catalog', this.currentSession.initialCatalogKey], { replaceUrl: true })
		this.isCatalog = true;
		this.locationChanged("catalog")
	}

	public msalLoaded() {
		this.msalLoading.next();
		this.msalAllreadyLoaded = true;
	}

	private createURLSegment(args) {
		let params = "";
		Object.keys(args).forEach(key => {
			if (typeof (args[key]) === "boolean") {
				params += "/" + key;
			} else if (typeof (args[key]) === "string") {
				params += "/" + key + "/" + args[key];
			}
		});

		return params;
	}

	private callback(callback: SoftwareIntegrationApiCallbackType, args: any) {
		if (isNullish(this.currentSession) || !Array.from(this.currentSession?.registeredCallbacks).includes(callback)) {
			return;
		}
		let comInteropEnabled
		try {
			// Callback darf nicht mit MathUtils abgefragt werden da dies ein falsches ergebnis liefert
			comInteropEnabled = !isNullish((window as any).chrome?.webview?.hostObjects?.Callback);
		}
		catch (err) {
			comInteropEnabled = false;
		}
		const method = this.getMethod(callback);
		if (comInteropEnabled) {

			if (this.currentSession.callbackMode === SoftwareIntegrationApiCallbackMode.PostMessage) {
				// TODO prüfen ob dieser Fall möglich/notwendig ist
				// TODO evtl. Orca.Ade.ApiExample erweitern, damit dieser Fall getestet werden kann
				const postObj = {
					parameters: args,
					method,
					url: this.currentSession.apiBaseUrl + "callback/" + method + this.createURLSegment(args)
				};
				// TODO wie wird AdeApi.config.CallbackTargetOrigin geprüft/gesendet ?
				(window as any).chrome.webview.postMessage(postObj);
			} else {
				(window as any).chrome.webview.hostObjects.AdeApi.Callback(method, JSON.stringify(args));
			}
		} else {
			if (this.currentSession.callbackMode === SoftwareIntegrationApiCallbackMode.PostMessage && window.parent) {
				// TODO Orca.Ade.ApiExample erweitern, damit dieser Fall getestet werden kann
				const postObj = {
					parameters: args,
					method,
					url: this.currentSession.apiBaseUrl + "callback/" + method + this.createURLSegment(args)
				}
				window.parent.postMessage(postObj, this.currentSession.callbackTargetOrigin);

			} else {
				// TODO Orca.Ade.ApiExample erweitern, damit dieser Fall getestet werden kann
				const params = this.createURLSegment(args);
				window.location.href = this.currentSession.apiBaseUrl + "callback/" + method + params;
			}
		}
	}

	public get ApplicationKey() {
		return this.currentSession?.applicationKey;
	}

	public get HostAppUserAgent() {
		return this.currentSession?.hostAppUserAgent;
	}

	// TODO: Can be delete after analytics tracking service was removed
	public async getSoftwareIntegrationApiSession(sessionId: string): Promise<SoftwareIntegrationApiSessionDto> {
		if (isNullish(sessionId) || sessionId === "") {
			return null
		}

		try {
			return this.accountPublicApi.softwareIntegrationApi_GetSession(sessionId).toPromise()

		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HttpStatusCode.NoContent, return: undefined }
			]);
		}
	}

	public async updateSessionUiSelectionDataAsync(node?: INode, nodes?: INode[]) {
		const sessionId = this.globalVarService.getSoftwareIntegrationSessionId();
		const dto = {
			currentElement: node ? { catalogItemId: node?.id, catalogItemGuid: node?.guid } : undefined,
			selectedElements: nodes ? nodes.map(selectedNode => ({ catalogItemId: selectedNode?.id, catalogItemGuid: selectedNode?.guid })) : undefined
		}

		const currentSession = await this.accountPublicApi.softwareIntegrationApi_UpdateSessionUiSelectionData(sessionId, dto).toPromise()
		if (!isNullish(currentSession)) {
			this.currentSession = currentSession;
		}
	}

	public async updateSessionUiDataAsync(dto: SoftwareIntegrationApiUiDataDto): Promise<SoftwareIntegrationApiSessionDto> {
		try {
			return this.accountPublicApi.softwareIntegrationApi_UpdateSessionUiData(this.globalVarService.getSoftwareIntegrationSessionId(), dto).toPromise()
		} catch (e) {
			return this.requestService.errorHandling(e, null, [
				{ status: HttpStatusCode.NoContent, return: undefined }
			]);
		}
	}

	private locationChanged(location: string) {
		if (this.hasCallback(SoftwareIntegrationApiCallbackType.LocationChanged)) {
			this.callback(SoftwareIntegrationApiCallbackType.LocationChanged, { location });
		}

	}

	public hasSession(): boolean {
		return !isNullish(this.globalVarService.getSoftwareIntegrationSessionId())
	}

	public hasCallback(type: SoftwareIntegrationApiCallbackType) {
		return this.hasSession && this.currentSession?.registeredCallbacks?.includes(type);
	}

	public export() {
		this.callback(SoftwareIntegrationApiCallbackType.Export, { "source": "current" });
	}

	public getCatalogItemLink() {
		this.callback(SoftwareIntegrationApiCallbackType.CatalogItemLink, { "source": "current" })
	}

	public dragStart() {
		this.callback(SoftwareIntegrationApiCallbackType.DragStart, { "source": "marked" });
	}
	private getMethod(callback: SoftwareIntegrationApiCallbackType) {
		switch (callback) {
			case SoftwareIntegrationApiCallbackType.DragStart: return 'dragstart';
			case SoftwareIntegrationApiCallbackType.Export: return 'export';
			case SoftwareIntegrationApiCallbackType.LocationChanged: return 'locationchanged';
			case SoftwareIntegrationApiCallbackType.CatalogItemLink: return 'catalogItemLink'
		}
	}
	public onClose(): void {
		this.locationChanged("unknown");
	}
}


