// ACHTUNG!!! SEO-kritische Komponente, Direkte Importe und Backendaufrufe sollten so gering wie nur möglich gehalten werden 


import { NgClass } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountLanguage } from '@interfaces/HttpClient/AccountApiPublicModels';
import { SoftwareIntegrationCallbackService } from '@services/software-integration-callback.service';
import { SoftwarepartnerApiService } from '@services/softwarepartner-api.service';
import { HelpPublicComponent } from "../../../components/help-public/help-public.component";
import { LanguageComponent } from "../../../components/language/language.component";
import { UserMenuContextComponent } from "../../../components/user-menu/user-menu-context/user-menu-context.component";
import { WatchlistHeaderNavigationComponent } from "../../../components/watchlist-header-navigation/watchlist-header-navigation.component";
import { HeaderBurgerMenuComponent } from "../header-burger-menu/header-burger-menu.component";

@Component({
	selector: 'app-mobile-header',
	standalone: true,
	changeDetection: ChangeDetectionStrategy.OnPush,
	templateUrl: './mobile-header.component.html',
	styleUrls: ['./mobile-header.component.scss'],
	imports: [NgClass, RouterModule, HeaderBurgerMenuComponent, HelpPublicComponent, UserMenuContextComponent, WatchlistHeaderNavigationComponent, LanguageComponent]
})
export class MobileHeaderComponent implements OnInit {
	@Input() catalogKey: string
	@Input() theme: "ade" | "neutral" = "neutral"
	@Input() showLanguage = false;
	@Input() showWatchlist = false;
	@Output() languageChange = new EventEmitter<AccountLanguage>();
	@Output() signetClick = new EventEmitter();
	public hasWatchlist = false;

	constructor(private softwareIntegrationService: SoftwareIntegrationCallbackService, private softwarepartnerApiService: SoftwarepartnerApiService) {

	}
	ngOnInit(): void {
		this.hasWatchlist = this.showWatchlist && !this.softwareIntegrationService.hasSession() && !this.softwarepartnerApiService.hasSession();;
	}



}
