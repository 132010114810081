import { Injectable } from '@angular/core';
import { InvalidArgumentException } from '@app/exceptions/invalid-argument-exception';
import { ITrackEventParamsBase, TrackAttachmentParams, TrackCatalogExternalParams, TrackCatalogItemParams, TrackCatalogListingParams, TrackCatalogParams, TrackCatalogSuggestionParams, TrackContactFormOpenParams, TrackContactFormSubmitParams, TrackExportParams, TrackExportsParams, TrackLoginRequestParams, TrackLoginSuccessParams, TrackSearchCatalogParams, TrackSearchGlobalParams, TrackShareCatalogItemParams } from '@app/models/tracking/track-event-param-type';
import { ITrackHistoryParamsBase, NavigateAzParams, NavigateCatalogParams, NavigateCategoryParams, NavigateFromCatalogSearchCatalogItemResultToCatalogItemParams, NavigateFromCatalogSearchToCatalogSearchResultParams, NavigateFromGlobalSearchCatalogItemResultToCatalogParams, NavigateFromGlobalSearchCatalogResultToCatalogParams, NavigateFromSearchbarIntoCatalog, NavigateHomeParams, NavigateInternationalCatalogParams, NavigateLinkedCatalogParams, NavigateLogoCarouselParams, NavigateLogoWallParams, NavigateNoResultParams, NavigateSearchResultIfNotSetParams, NavigateSearchResultParams, NavigateSearchSuggestionParams, NavigateSearchbarParams, ResetLoginParams, SearchGuidChangedParams, SetCategorySearchSuggestionSourceParams, SetSearchResultCatalogSuggestionSourceParams, SetStartpageSearchSuggestionSourceParams } from '@app/models/tracking/track-history-param-type';
import { isNullish } from '@commonHelpers/math-utils';
import { CatalogSuggestionSource, CatalogViewOrigin, SearchOrigin, SearchSuggestionSource } from '@interfaces/HttpClient/AnalyticsApiTrackingModels';
import { AnalyticsTrackingApplicationStateService } from './analytics-tracking-application-state.service';
import { AnalyticsTrackingEventAggregatorService } from './analytics-tracking-event-aggregator.service';
import { AnalyticsTrackingHistoryService } from './analytics-tracking-history.service';
import { AnalyticsTrackingQueueService } from './analytics-tracking-queue.service';
import { AuthenticatedUserService } from './authenticated-user.service';
import { SessionService } from './session.service';
import { UserService } from './user.service';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsTrackingFacadeService {

	constructor(
		private trackingAggregatorService: AnalyticsTrackingEventAggregatorService,
		private trackingHistoryService: AnalyticsTrackingHistoryService,
		private analyticsTrackingQueueService: AnalyticsTrackingQueueService,
		private analyticsTrackingApplicationStateService: AnalyticsTrackingApplicationStateService,
		private sessionService: SessionService,
		private userService: UserService,
		private authenticatedUserService: AuthenticatedUserService
	) { }

	public handleTrackEvent(params: ITrackEventParamsBase): void {
		if (params instanceof TrackAttachmentParams) {
			this.trackingAggregatorService.trackAttachment(params);
		} else if (params instanceof TrackExportParams) {
			this.trackingAggregatorService.trackExport(params);
		} else if (params instanceof TrackExportsParams) {
			this.trackingAggregatorService.trackExports(params);
		} else if (params instanceof TrackCatalogParams) {
			this.trackingAggregatorService.trackCatalog(params);
		} else if (params instanceof TrackCatalogItemParams) {
			if (!isNullish(params.catalogItemGuid)) {
				this.trackingAggregatorService.trackCatalogItem(params);
			}
		} else if (params instanceof TrackCatalogListingParams) {
			this.trackingAggregatorService.trackCatalogListing(params);
		} else if (params instanceof TrackCatalogSuggestionParams) {
			this.trackingAggregatorService.trackCatalogSuggestion(params);
		} else if (params instanceof TrackCatalogExternalParams) {
			this.trackingAggregatorService.trackCatalogExternal(params);
		} else if (params instanceof TrackContactFormOpenParams) {
			this.trackingAggregatorService.trackContactFormOpen(params);
		} else if (params instanceof TrackContactFormSubmitParams) {
			this.trackingAggregatorService.trackContactFormSubmit(params);
		} else if (params instanceof TrackLoginRequestParams) {
			this.sessionService.setLoginSource(params.source);
			this.trackingAggregatorService.trackLoginRequest(params);
		} else if (params instanceof TrackLoginSuccessParams) {
			this.trackingAggregatorService.trackLoginSuccess(params);
			this.sessionService.deleteLoginSource();
		} else if (params instanceof TrackSearchGlobalParams) {
			this.handleTrackHistory(new SearchGuidChangedParams(params.searchGuid))
			this.trackingAggregatorService.trackSearchGlobal(params);
		} else if (params instanceof TrackSearchCatalogParams) {
			this.handleTrackHistory(new SearchGuidChangedParams(params.searchGuid))
			this.trackingAggregatorService.trackSearchCatalog(params);
		} else if (params instanceof TrackShareCatalogItemParams) {
			this.trackingAggregatorService.trackShareCatalogItem(params);
		} else {
			throw new InvalidArgumentException("Params is not an instance of any of the classes defined above.");
		}
	}

	public handleTrackHistory(params: ITrackHistoryParamsBase) {
		if (params instanceof NavigateFromCatalogSearchToCatalogSearchResultParams) {
			if (!isNullish(params.indexedTerm) && (params.term === params.indexedTerm) || !isNullish(params.isSuggestionClicked) && !params.isSuggestionClicked) {
				this.trackingHistoryService.setSearchSuggestionSource(SearchSuggestionSource.SearchTerm);
			} else {
				this.trackingHistoryService.setSearchSuggestionSource(SearchSuggestionSource.None);
			}
		}
		else if (params instanceof NavigateFromCatalogSearchCatalogItemResultToCatalogItemParams) {
			this.trackingHistoryService.setSearchResultHistory(params.searchResultPage, params.searchResultPosition)
		}
		else if (params instanceof NavigateFromGlobalSearchCatalogItemResultToCatalogParams) {
			this.trackingHistoryService.setSearchOrigin(SearchOrigin.SearchResult);
			this.trackingHistoryService.setCatalogViewOrigin(CatalogViewOrigin.SearchResult);
			this.trackingHistoryService.setSearchResultHistory(params.searchResultPage, params.searchResultPosition)
		}
		else if (params instanceof NavigateFromGlobalSearchCatalogResultToCatalogParams) {
			this.trackingHistoryService.setSearchOrigin(SearchOrigin.SearchResult);
			this.trackingHistoryService.setCatalogViewOrigin(CatalogViewOrigin.SearchResultCatalogList);


		} else if (params instanceof NavigateHomeParams) {
			this.trackingHistoryService.setSearchOrigin(SearchOrigin.Startpage);
			this.trackingHistoryService.setCatalogSuggestionSource(CatalogSuggestionSource.Startpage);
		} else if (params instanceof NavigateAzParams) {
			this.trackingHistoryService.setCatalogViewOrigin(CatalogViewOrigin.Az)
			this.trackingHistoryService.setSearchOrigin(SearchOrigin.Az);
			this.trackingHistoryService.setCatalogSuggestionSource(CatalogSuggestionSource.Az);
		} else if (params instanceof NavigateCategoryParams) {
			this.trackingHistoryService.setCatalogViewOrigin(CatalogViewOrigin.Category)
			this.trackingHistoryService.setSearchOrigin(SearchOrigin.Category);
			this.trackingHistoryService.setCatalogSuggestionSource(CatalogSuggestionSource.Categories);
		} else if (params instanceof NavigateCatalogParams) {
			if (!params.isInSearchView || !this.trackingHistoryService.hasSearchOrigin()) {
				this.trackingHistoryService.setSearchOrigin(SearchOrigin.Catalog);
			}
		} else if (params instanceof NavigateInternationalCatalogParams) {
			this.trackingHistoryService.setCatalogViewOrigin(CatalogViewOrigin.International)
			this.trackingHistoryService.setSearchOrigin(SearchOrigin.International);
			this.trackingHistoryService.setCatalogSuggestionSource(CatalogSuggestionSource.International);
		} else if (params instanceof NavigateLinkedCatalogParams) {
			this.trackingHistoryService.setCatalogViewOrigin(CatalogViewOrigin.LinkedCatalogs)
			this.trackingHistoryService.setSearchSuggestionSource(SearchSuggestionSource.LinkedCatalog)
		} else if (params instanceof NavigateLogoCarouselParams) {
			this.trackingHistoryService.setCatalogViewOrigin(CatalogViewOrigin.LogoCarousel)
		} else if (params instanceof NavigateLogoWallParams) {
			this.trackingHistoryService.setCatalogViewOrigin(CatalogViewOrigin.LogoWall)
		} else if (params instanceof NavigateNoResultParams) {
			if (isNullish(params.catalogKey)) {
				this.trackingHistoryService.setSearchSuggestionSource(SearchSuggestionSource.SearchTerm);
			} else {
				this.trackingHistoryService.setSearchSuggestionSource(SearchSuggestionSource.LinkedCatalog);
			}
		} else if (params instanceof NavigateSearchResultIfNotSetParams) {
			if (!this.trackingHistoryService.hasSearchOrigin()) {
				this.trackingHistoryService.setSearchOrigin(SearchOrigin.SearchResult);
			}
		} else if (params instanceof NavigateSearchResultParams) {
			this.trackingHistoryService.setSearchOrigin(SearchOrigin.SearchResult);
		} else if (params instanceof NavigateSearchSuggestionParams) {
			this.trackingHistoryService.setSearchOrigin(SearchOrigin.Catalog);
		} else if (params instanceof NavigateFromSearchbarIntoCatalog) {
			this.trackingHistoryService.setSearchSuggestionSource(SearchSuggestionSource.Catalog);
			this.trackingHistoryService.setCatalogViewOrigin(CatalogViewOrigin.SearchSuggestion);
		} else if (params instanceof SetCategorySearchSuggestionSourceParams) {
			this.trackingHistoryService.setSearchSuggestionSource(SearchSuggestionSource.Category);
		} else if (params instanceof SetStartpageSearchSuggestionSourceParams) {
			this.trackingHistoryService.setSearchSuggestionSource(SearchSuggestionSource.Startpage);
		} else if (params instanceof SetSearchResultCatalogSuggestionSourceParams) {
			this.trackingHistoryService.setCatalogSuggestionSource(CatalogSuggestionSource.SearchResult);
		} else if (params instanceof NavigateSearchbarParams) {
			if (params.useSearchTerm) {
				this.trackingHistoryService.setSearchSuggestionSource(SearchSuggestionSource.SearchTerm);
			} else {
				this.trackingHistoryService.setSearchSuggestionSource(SearchSuggestionSource.None);
			}
		} else if (params instanceof SearchGuidChangedParams) {
			this.trackingHistoryService.setPostSearchEventHistoryState(params);
		} else if (params instanceof ResetLoginParams) {
			this.sessionService.generateNewSession();
		}

		else {
			throw new InvalidArgumentException("Params is not an instance of any of the classes defined above.");
		}
	}
	// Wird bei AADB2C Navigationen benötigt um alle aktuellen Trackevents abzuarbeiten die momentan in der Warteschlange drin sind
	public async commitAllTrackEvents() {
		await this.analyticsTrackingQueueService.commitAllTrackEvents();
	}

	// Darf erst dann ausgefüht werden wenn msal abgeabeitet wird
	public async initTracking() {
		this.authenticatedUserService.init();
		this.userService.init();
		this.sessionService.init();
		await this.analyticsTrackingApplicationStateService.init();
		this.analyticsTrackingQueueService.init();
	}

	public getState() {
		return this.analyticsTrackingApplicationStateService.getState();
	}

	public getReferrer() {
		return this.getState()?.referrer;
	}

	public endSearch() {
		this.trackingHistoryService.endSearch();
	}
}
