import { GlobalTranslationVar } from "@commonClass/GlobalTranslationVar"

export const limitToTwoDecimalPlaces = (number: number): number => limitToDecimalPlaces(number, 2)

export const limitToDecimalPlaces = (number: number, numberOfDecimalPlaces: number): number => {
	if (numberOfDecimalPlaces < 0) { throw new Error("number-formater.ts: The number of decimal places to limit to must be positive or 0.") }
	if (numberOfDecimalPlaces === 0) {
		return Math.round(number)
	}
	const limiter = 10 * numberOfDecimalPlaces
	return Math.round(number * limiter) / limiter
}

export const localeNumberFormat = (value: number | string): string => typeof value === "number" || (!Number.isNaN(+value) && value !== "") ? new Intl.NumberFormat(GlobalTranslationVar.SELECTED_LANGUAGE).format(+value) : value;