<div ngbNav #nav="ngbNav" #navElement [activeId]="active" [attr.role]="'tablist'" class="nav nav-tabs">
	@for (tab of tabsToShow; track tab; let idx = $index) {
		<a
			#tabElement
			[ngbNavItem]="tab"
			class="d-flex text-dark"
			[routerLink]="getTabRouterLink(tab)"
			[queryParams]="getQueryParams(tab, idx)"
			queryParamsHandling="merge"
			ngbNavLink>
			<!-- {{setPanel(tabElement)}} -->
			@if (tab === ViewGroups.Protocol) {
				<div loading="lazy" class="flag-image mask-settings grid-mask-img bg bg-primary flag-filled-mask-img"></div>
			}
			<div>
				{{ getTabKey(tab) | translate }}
				@if (tab === ViewGroups.Sustainability) {
					<img
						src="/assets/sustainability/dgnb/nachhaltigkeit.svg"
						class="sustainability-image"
						loading="eager"
						alt="{{ getTabKey(tab) | translate }}" />
				}
			</div>
		</a>
	}
</div>
