import { Directive, HostListener } from '@angular/core';
import { MarkedTextService } from '@services/marked-text.service';

@Directive({
    selector: '[appHasMarkedText]',
    standalone: true
})
export class HasMarkedTextDirective {

	constructor(
		private markedTextService: MarkedTextService
	) { }

	@HostListener("mouseup")
	hasMarkedText() {
		if (window.getSelection().toString()) {
			this.markedTextService.setTrue()
		} else {
			this.markedTextService.setFalse()
		}
	}
}
