export type CollectionPredicate = (item?: any, index?: number, collection?: any[]) => boolean;

export const isUndefined = (value: any): value is undefined => typeof value === 'undefined'

export const isNull = (value: any): value is null => value === null

export const isNumber = (value: any): value is number => typeof value === 'number'

export const isNumberFinite = (value: any): value is number => isNumber(value) && isFinite(value)

// Not strict positive
export const isPositive = (value: number): boolean => value >= 0

export const isInteger = (value: number): boolean => value % 1 === 0 // No rest, is an integer 

export const isNullish = (value: any): value is null | undefined => typeof value === 'undefined' || value === null

export const isNullishOrEmpty = (value: any): value is null | undefined => typeof value === 'undefined' || value === null || value === ""

export const isString = (value: any): value is string => typeof value === 'string'

export const isObject = (value: any): boolean => value !== null && typeof value === 'object'

export const isArray = (value: any): boolean => Array.isArray(value)

export const isFunction = (value: any): boolean => typeof value === 'function'

export const toDecimal = (value: number, decimal: number): number => Math.round(value * Math.pow(10, decimal)) / Math.pow(10, decimal)

// numerator (dt. Zähler): Der Anteil eines Ganzen, der als Prozentwert ausgedrückt werden soll
// denominator (dt. Nenner): Das Ganze, im Verhältnis zu dem der Prozentwert berechnet werden soll
export const toPercentage = (numerator: number, denominator: number): number => denominator > 0 ? numerator / denominator * 100 : 0;
