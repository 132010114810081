import { animate, keyframes, state, style, transition, trigger } from '@angular/animations';

export const STANDARD_TIMING = '0.3s ease-out'

export const slide = trigger('slide', [

	transition(':enter', [
		style({ transform: 'translateY(100%)' }),
		animate(STANDARD_TIMING)
	]),

	transition(':leave', [
		animate(STANDARD_TIMING, keyframes([
			style({
				transform: 'translateY(100%)'
			}),
		]))
	]),
])

export const bumpIn = trigger('bumpIn', [

	state('idle-bumpIn', style({ opacity: 0 })),

	transition(':enter', [

		style({ transform: 'scale(0.5)', opacity: 0 }),

		animate("{{timing}} {{delay}} cubic-bezier(.8, -0.6, 0.2, 1.5)",

			style({ transform: 'scale(1)', opacity: 1 }))

	], { params: { timing: '500ms', delay: '' } })
]);

export const fadeIn = trigger('fadeIn', [
	transition(':enter', [

		style({ opacity: 0 }),

		animate('{{timing}} {{delay}} ease-in', style('*'))

	], { params: { timing: '300ms', delay: '' } }),
])

export const fadeOut = trigger('fadeOut', [
	transition(':leave', [

		animate('{{timing}} {{delay}} ease-out', style({ opacity: 0 }))

	], { params: { timing: '500ms', delay: '' } }),
])

export const fadeInBottom = trigger('fadeInBottom', [
	transition(':enter', [

		style({ opacity: 0, transform: 'translateY(20px)' }),

		animate('{{timing}} {{delay}} ease-in', style('*'))

	], { params: { timing: '500ms', delay: '' } }),
])

export const fadeOutBottom = trigger('fadeOutBottom', [
	transition(':leave', [

		animate('{{timing}} {{delay}} ease-out', style({ opacity: 0, transform: 'translateY(20px)' }))

	], { params: { timing: '500ms', delay: '' } }),
])

export const slideInTop = trigger('slideInTop', [
	transition(':enter', [

		style({ opacity: 0, height: 0 }),

		animate('{{timing}} {{delay}} ease-in', style('*'))

	], { params: { timing: '300ms', delay: '' } }),
])

export const slideOutTop = trigger('slideOutTop', [
	transition(':leave', [

		animate('{{timing}} {{delay}} ease-out', style({ opacity: 0, height: 0 }))

	], { params: { timing: '500ms', delay: '' } }),
])
