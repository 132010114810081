
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { fadeIn, fadeOut } from '@app/animations/animations';
import { ALERT_DISPLAY_TYPES, ALERT_POSITION_TYPES, Alert, AlertService } from '@services/alert.service';
import { Subscription, timer } from 'rxjs';
import { filter } from 'rxjs/operators';
import { AlertUiComponent } from './alert-ui/alert-ui.component';

@Component({
	selector: 'app-alert',
	templateUrl: './alert.component.html',
	styleUrls: ['./alert.component.scss'],
	animations: [fadeIn, fadeOut],
	standalone: true,
	imports: [AlertUiComponent]
})
export class AlertComponent implements OnInit, OnDestroy {

	ALERT_DISPLAY_TYPES = ALERT_DISPLAY_TYPES
	ALERT_POSITION_TYPES = ALERT_POSITION_TYPES

	@Input() positionType: ALERT_POSITION_TYPES
	alertSubscription: Subscription
	alert: Alert

	constructor(
		private alertService: AlertService,
	) { }

	ngOnInit(): void {

		this.subscribeToAlertService()
	}

	subscribeToAlertService() {
		this.alertSubscription = this.alertService.getMessage()
			.pipe(
				filter(alert => alert?.positionType === this.positionType),
			)
			.subscribe(alert => {
				this.alert = alert;
				const alertTimer = timer(5000).subscribe(() => { this.alert = null; alertTimer.unsubscribe() });
			})
	}

	ngOnDestroy() {
		this.alertSubscription.unsubscribe()
	}
}