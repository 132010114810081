import { CatalogViewTreeItemType } from "@commonEnums/CatalogViewTreeItemType";


const iconFolder = "assets/orca/catalogTree/closedFolder.svg";
const iconFolderPlus = "assets/orca/catalogTree/closedFolderPlus.svg";
const iconPosition = "assets/orca/catalogTree/outline_note.svg";
const iconText = "assets/orca/catalogTree/outline_description.svg";
const iconAttachments = "assets/orca/catalogTree/outline_note_add.svg";
const iconTextAttachments = "assets/orca/catalogTree/outline_text_plus.svg";
const iconPositionWatchlist = "assets/orca/catalogTree/file-gemerkt.svg";
const iconTextWatchlist = "assets/orca/catalogTree/file-text-gemerkt.svg";
const iconTextPlusWatchlist = "assets/orca/catalogTree/file-text-plus-gemerkt.svg";
const iconAttachmentsWatchlist = "assets/orca/catalogTree/file-plus-gemerkt.svg";
const iconFolderWatchlist = "assets/orca/catalogTree/folder-gemerkt.svg";
const iconFolderPartlyWatchlist = "assets/orca/catalogTree/folder-halb-gemerkt.svg";
const iconPlusFolderWatchlist = "assets/orca/catalogTree/folder-plus-gemerkt.svg";
const iconPlusFolderPartlyWatchlist = "assets/orca/catalogTree/folder-plus-halb-gemerkt.svg";

const iconFolderClass = "closed-folder-mask-img";
const iconFolderPlusClass = "closed-folder-plus-mask-img";
const iconPositionClass = "note-mask-img";
const iconTextClass = "description-mask-img";
const iconAttachmentsClass = "note-add-mask-img";
const iconTextAttachmentsClass = "text-plus-mask-img";

export const getIconByCatalogViewTreeItemType = (state: CatalogViewTreeItemType, isInWatchlist?: boolean, isPartlyInWatchlist?: boolean, customIcon?: string) => {
	switch (state) {
		case CatalogViewTreeItemType.Folder: return isInWatchlist ? iconFolderWatchlist : isPartlyInWatchlist ? iconFolderPartlyWatchlist : iconFolder;
		case CatalogViewTreeItemType.FolderWithAttachments: return isInWatchlist ? iconPlusFolderWatchlist : isPartlyInWatchlist ? iconPlusFolderPartlyWatchlist : iconFolderPlus;
		case CatalogViewTreeItemType.TextOrDocumentWithAttachments: return isInWatchlist ? iconTextPlusWatchlist : iconTextAttachments;
		case CatalogViewTreeItemType.PositionWithAttachments: return isInWatchlist ? iconAttachmentsWatchlist : iconAttachments;
		case CatalogViewTreeItemType.TextOrDocument: return isInWatchlist ? iconTextWatchlist : iconText;
		case CatalogViewTreeItemType.Position: return isInWatchlist ? iconPositionWatchlist : iconPosition;
		case CatalogViewTreeItemType.CustomItem: return customIcon;
		default: return undefined;
	}

}

export const getIconClassByCatalogViewTreeItemType = (state: CatalogViewTreeItemType, customIconClass?: string) => {
	switch (state) {
		case CatalogViewTreeItemType.Folder: return iconFolderClass;
		case CatalogViewTreeItemType.FolderWithAttachments: return iconFolderPlusClass;
		case CatalogViewTreeItemType.TextOrDocumentWithAttachments: return iconTextAttachmentsClass;
		case CatalogViewTreeItemType.PositionWithAttachments: return iconAttachmentsClass;
		case CatalogViewTreeItemType.TextOrDocument: return iconTextClass;
		case CatalogViewTreeItemType.Position: return iconPositionClass;
		case CatalogViewTreeItemType.CustomItem: return customIconClass;
		default: return undefined;
	}
}