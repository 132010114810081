import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export enum ALERT_POSITION_TYPES {
	GLOBAL = "global",
	BUTTON_COPY_VIDEO_LINK = "button_copy_video_link",
	ACTION_BUTTON_TOP = "action_button_top",
}

export enum ALERT_DISPLAY_TYPES {
	DANGER = "danger",
	INFO = "info",
	SUCCESS = "success",
	WARNING = "warning",
}

export interface Alert {
	positionType: ALERT_POSITION_TYPES,
	displayType: ALERT_DISPLAY_TYPES,
	text: string
}


@Injectable({
	providedIn: "root"
})
export class AlertService {
	private subject = new Subject<any>();

	sendMessage(positionType: ALERT_POSITION_TYPES, displayType: ALERT_DISPLAY_TYPES, text: string) {
		this.subject.next(
			{
				positionType,
				displayType,
				text
			}
		);
	}

	clearMessage(positionType) {
		this.subject.next({ positionType });
	}

	getMessage(): Observable<any> {
		return this.subject.asObservable();
	}
}
