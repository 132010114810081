export const convertPascalCasetoCamelCase = (camelCaseString: string): string => {
	if (camelCaseString.length > 0) {
		return camelCaseString.charAt(0).toLowerCase() + camelCaseString.substring(1)
	}
}
// 
export const convertCamelCaseToPascalCase = (pascalCaseString: string): string => {
	if (pascalCaseString.length > 0) {
		return pascalCaseString.charAt(0).toUpperCase() + pascalCaseString.substring(1)
	}
}