import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
	providedIn: "root"
})
export class MarkedTextService {
	private subject = new Subject<boolean>();

	constructor() { }

	setTrue() {
		this.subject.next(true)
	}

	setFalse() {
		this.subject.next(false)
	}

	getState() {
		return this.subject.asObservable()
	}
}
