export interface CountValues {
	translationKey: string;
	count: number;
}

export interface CatalogViewDto {
	key: string;
	additionalCategoriesTexts?: string[]
	manufacturerName?: string | undefined;
	catalogDescription?: string | undefined;
	streetAndNumber?: string | undefined;
	countryZipAndCity?: string | undefined;
	phone?: string | undefined;
	fax?: string | undefined;
	email1?: string | undefined;
	email2?: string | undefined;
	webSite1?: string | undefined;
	webSite2?: string | undefined;
	countTexts: number;
	countImagesAndVideos: number;
	countDocuments: number;
	countCadAndBim: number;
	countLinks: number;
	socialMedia?: SocialMediaDto[] | undefined;
	lastUpdate: Date;
}

export interface SocialMediaDto {
	socialMediaType: SocialMediaType;
	domain?: string | undefined;
	allowedDomainNames?: string[] | undefined;
}

export interface TrackMailDto {
	viewType: CatalogExternalViewType,
	link: string
}

export interface TrackSocialMediaDto {
	socialMediaType: SocialMediaType,
	link: string
}

export enum SocialMediaType {
	Unknown = 0,
	Xing = 1,
	Facebook = 2,
	Twitter = 3,
	Instagram = 4,
	LinkedIn = 5,
	YouTube = 6,
	Pinterest = 7,
}

export enum CatalogExternalViewType {
	Unknown = 0,
	Email2 = 1,
	WebSite1 = 2,
	WebSite2 = 3,
	Facebook = 4,
	Instagram = 5,
	LinkedIn = 6,
	Pinterest = 7,
	Twitter = 8,
	Xing = 9,
	YouTube = 10,
}