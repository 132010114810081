@if (catalogViewShowPrice !== undefined && catalogViewShowPrice !== null && additionalInfos?.length) {
	<div
		class="d-flex flex-row flex-wrap border"
		[class.overlappingBorders]="costGroups?.length"
		[class.mb-3]="!costGroups?.length"
		appHasMarkedText>
		@for (addinfo of additionalInfos; track addinfo) {
			<div class="p-2">
				@switch (catalogViewShowPrice) {
					@case (displaySetting.Visible) {
						<div>
							<ng-container
								*ngTemplateOutlet="
									infoTemplate;
									context: {
										title: addinfo.title,
										text: addinfo.text,
										showTitleAsString: addinfo.showTitleAsString,
									}
								">
							</ng-container>
						</div>
					}
					@case (displaySetting.VisibleForRegisteredUsers) {
						<div>
							<!-- User is logged in -->
							@if (loggedIn) {
								<div>
									<ng-container
										*ngTemplateOutlet="
											infoTemplate;
											context: {
												title: addinfo.title,
												text: addinfo.text,
												showTitleAsString: addinfo.showTitleAsString,
											}
										">
									</ng-container>
								</div>
							} @else {
								@if (addinfo.isPrice) {
									<div>
										<ng-container
											*ngTemplateOutlet="
												infoTemplate;
												context: {
													title: addinfo.title,
													showTooltip: true,
													showTitleAsString: addinfo.showTitleAsString,
													tooltipKey: 'price-hint-registered-user',
												}
											">
										</ng-container>
									</div>
								} @else {
									<ng-container
										*ngTemplateOutlet="
											infoTemplate;
											context: {
												title: addinfo.title,
												text: addinfo.text,
												showTitleAsString: addinfo.showTitleAsString,
											}
										">
									</ng-container>
								}
							}
							<!-- User is not logged in -->
						</div>
					}
					@case (displaySetting.Hidden) {
						<div>
							@if (addinfo.isPrice) {
								<div>
									<ng-container
										*ngTemplateOutlet="
											infoTemplate;
											context: {
												title: addinfo.title,
												showTooltip: true,
												showTitleAsString: addinfo.showTitleAsString,
												tooltipKey: 'price-hint-hidden',
											}
										">
									</ng-container>
								</div>
							} @else {
								<ng-container
									*ngTemplateOutlet="
										infoTemplate;
										context: {
											title: addinfo.title,
											text: addinfo.text,
											showTitleAsString: addinfo.showTitleAsString,
										}
									">
								</ng-container>
							}
						</div>
					}
				}
			</div>
		}
	</div>
}
@if (costGroups?.length) {
	<div class="border mb-3 addtext" [class.pb-2]="!collapsed" appHasMarkedText>
		<div (click)="collapsed = !collapsed" class="fw-bold p-2 position-relative cursor-pointer">
			{{ 'catalog-view.additionalinfoviewer.costgroups' | translate }}
			@if (collapsed) {
				<div class="collapsMarker">
					<button
						aria-label="open costGroups"
						class="navigate-buttons bg-gray-500 mask-settings keyboard-arrowdown-mask-img"></button>
				</div>
			} @else {
				<div class="collapsMarker">
					<button
						aria-label="close costGroups"
						class="navigate-buttons bg-gray-500 mask-settings keyboard-arrowup-mask-img"></button>
				</div>
			}
		</div>
		<div class="collapsable mb-0" [class.collapsed]="collapsed">
			@for (addinfo of costGroups; track addinfo) {
				<div class="px-2">
					<dl class="d-flex mb-0">
						<dt class="fw-bold text-gray-500 key-title">
							{{
								addinfo.showTitleAsString
									? addinfo.title
									: (addinfo.title | toResourceKey: 'catalog-view.additionalinfoviewer.' | translate)
							}}
						</dt>
						<dd class="ms-1 mb-0">{{ addinfo.text }}</dd>
					</dl>
				</div>
			}
		</div>
	</div>
}

<ng-template
	#infoTemplate
	let-title="title"
	let-text="text"
	let-showTitleAsString="showTitleAsString"
	let-showTooltip="showTooltip"
	let-tooltipKey="tooltipKey">
	<dl class="align-items-center d-flex mb-0">
		<dt class="addtext fw-bold key-title">
			{{ showTitleAsString ? title : (title | toResourceKey: 'catalog-view.additionalinfoviewer.' | translate) }}
		</dt>
		@if (text) {
			<dd class="addtext ms-1 mb-0">{{ text }}</dd>
		}
		@if (showTooltip) {
			<dd
				[ngbTooltip]="tooltipKey | toResourceKey: 'catalog-view.additionalinfoviewer.' | translate"
				class="action-button bg-gray-500 mask-settings mb-0 info-mask-img info-position"></dd>
		}
	</dl>
</ng-template>
