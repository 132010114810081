import { Directive, ElementRef, EventEmitter, Input, Output } from "@angular/core";
import { enterFullscreen, isFullscreen } from "@app/helpers/fullscreen-helper";
import { TrackAttachmentParams } from "@app/models/tracking/track-event-param-type";
import { isNullish } from "@commonHelpers/math-utils";
import { CatalogDetailsType } from "@enums/catalog-details-type";
import { ViewType } from "@enums/view-type";
import { ViewerNavControlEnum } from "@enums/viewer-nav-control";
import { ExportSource, LoginSource } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";
import { CatalogLanguage, FeatureToggleValuesDto, ViewGroupItemDto } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { ICatalogRoutingParams } from "@interfaces/iCatalogRoutingParams";
import { AnalyticsTrackingFacadeService } from "@services/analytics-tracking-facade.service";
import { MetadataDownloadService } from "@services/metadata-download.service";
import { ViewTypeService } from "@services/view-type.service";
import { Subscription, timer } from "rxjs";

@Directive()
export abstract class CatalogViewerNavControls {
	public displayUrl: string;
	public navControls: ViewerNavControlEnum[];
	private viewTypeSubscription: Subscription
	public currentViewType: ViewType
	public viewType = ViewType

	@Input() attachment: ViewGroupItemDto;
	@Input() attachments: ViewGroupItemDto[];
	@Input() catalogKey: string;
	@Input() catalogItemGuid: string;
	@Input() url: string;
	@Input() featureToggles: FeatureToggleValuesDto;
	@Input() currentRoutingParams: ICatalogRoutingParams;
	@Output() minimizeClicked: EventEmitter<void> = new EventEmitter<void>();
	@Output() forgeActivated: EventEmitter<void> = new EventEmitter<void>();
	@Input() catalogDetailsType: CatalogDetailsType;
	@Input() catalogLanguage: CatalogLanguage;
	constructor(
		protected metadataDownloadService?: MetadataDownloadService,
		protected elRef?: ElementRef,
		protected viewTypeService?: ViewTypeService,
		protected trackingFacadeService?: AnalyticsTrackingFacadeService
	) {
	}
	public async navControlClicked(event) {
		switch (event) {
			case ViewerNavControlEnum.DOWNLOAD: {
				this.downloadFile()
				break;
			}
			case ViewerNavControlEnum.ZOOMIN: {
				this.zoomIn();
				break;
			}
			case ViewerNavControlEnum.ZOOMOUT: {
				this.zoomOut();
				break;
			}
			case ViewerNavControlEnum.RESET: {
				this.reset();
				break;
			}
			case ViewerNavControlEnum.TOP: {
				this.top();
				break;
			}
			case ViewerNavControlEnum.FRONT: {
				this.front();
				break;
			}
			case ViewerNavControlEnum.SIDE: {
				this.side();
				break;
			}
			case ViewerNavControlEnum.VIEW_2D: {
				await this.view2d();
				break;
			}
			case ViewerNavControlEnum.VIEW_3D: {
				await this.view3d();
				break;
			}
			case ViewerNavControlEnum.FULLSCREEN: {
				await this.fullscreen();

				// Tracking: Zoom Ansicht
				this.trackAttachmentLargeViewer(isNullish(this.attachment?.id) ? null : this.attachment.id);

				break;
			}
			default: {
				return;
			}
		}
	}

	protected async fullscreen() {
		await enterFullscreen(this.elRef.nativeElement.querySelector('.fullscreen'))
		this.enterViewerFullscreen();
	}

	protected init() {
		if (!isNullish(this.catalogItemGuid) && !isNullish(this.attachment?.id) && this.catalogDetailsType !== CatalogDetailsType.Preview) {
			this.trackingFacadeService.handleTrackEvent(new TrackAttachmentParams(
				this.catalogKey,
				this.catalogItemGuid,
				this.attachment.id,
				false,
				false
			))
		}
		this.viewTypeSubscription = this.viewTypeService.currentViewType$?.subscribe(viewType => this.currentViewType = viewType);
	}

	protected destroyViewType() {
		this.viewTypeSubscription?.unsubscribe();
	}

	public downloadFile() {
		this.metadataDownloadService.downloadFile(this.attachment, LoginSource.Viewer, ExportSource.Viewer, this.catalogKey, this.catalogItemGuid)
			.then(() => { })
			.catch(() => { })
			.finally(() => { });
	}

	// Diese Methoden werden wenn benötigt in den Kindklassen überschrieben
	public zoomIn() {

	}
	public zoomOut() {

	}
	public reset() {

	}
	public front() {

	}
	public top() {

	}
	public side() {

	}

	protected async view2d() {

	}

	protected async view3d() {

	}


	protected trackAttachmentLargeViewer(attachmentId: number) {
		timer(200).subscribe(() => {
			// Tracking: Zoom Ansicht
			// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
			// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/812/Attachment
			if (this.catalogDetailsType !== CatalogDetailsType.Preview) {
				this.trackingFacadeService.handleTrackEvent(new TrackAttachmentParams(
					this.catalogKey,
					this.catalogItemGuid,
					attachmentId,
					isFullscreen(),
					false
				))
			}
		})

	}

	protected enterViewerFullscreen() {
		// can be overwritten in viewer component
	}

	protected initCanonical() {
		const head = document.getElementsByTagName('head')[0];
		let element: HTMLLinkElement = document.querySelector(`link[rel='canonical']`) || null
		element = document.createElement('link') as HTMLLinkElement;
		head.appendChild(element);
		element.setAttribute('rel', 'canonical')
		element.setAttribute('href', `${window.location.origin}${window.location.pathname}`)
	}

	protected deleteCanonical() {
		// Kanonische URL vom Head löschen
		const head = document.getElementsByTagName('head')[0];
		const element: HTMLLinkElement = document.querySelector(`link[rel='canonical']`) || null
		if (!isNullish(element)) {
			head.removeChild(element);
		}
	}
}
