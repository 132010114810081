import { Injectable } from "@angular/core";



@Injectable({
	providedIn: "root"
})
export class MaintenanceActivateGuard {
	constructor() { }

	async canActivate() {
		return true;// environment.maintenance;

	}

}