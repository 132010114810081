import { Injectable } from '@angular/core';
import { isNullish } from '@commonHelpers/math-utils';
import { CatalogSuggestionSource, CatalogViewOrigin, SearchOrigin, SearchSuggestionSource } from '@interfaces/HttpClient/AnalyticsApiTrackingModels';


interface ICatalogHistoryState {
	catalogViewOrigin: CatalogViewOrigin,
	searchResultPage: number,
	searchResultPosition: number,
	catalogSuggestionSource: CatalogSuggestionSource
}

interface ISearchHistoryState {
	searchOrigin: SearchOrigin,
	searchSuggestionSource: SearchSuggestionSource
}

interface IPostSearchEventHistoryState {
	searchGuid: string
}



@Injectable({
	providedIn: 'root'
})
export class AnalyticsTrackingHistoryService {
	private searchResultPage: number;
	private searchResultPosition: number;
	private searchGuid: string;
	private searchOrigin: SearchOrigin;
	private searchSuggestionSource: SearchSuggestionSource;
	private catalogSuggestionSource: CatalogSuggestionSource;
	private catalogViewOrigin: CatalogViewOrigin = CatalogViewOrigin.Other;

	constructor() { }

	/** State before a catalog view is triggered */
	getCatalogHistoryState(): ICatalogHistoryState {
		return {
			catalogViewOrigin: this.catalogViewOrigin,
			searchResultPage: this.searchResultPage,
			searchResultPosition: this.searchResultPosition,
			catalogSuggestionSource: this.catalogSuggestionSource
		}
	}

	// TODO: Ideally, ICatalogHistoryState would be set in a single method
	setCatalogViewOrigin(catalogViewOrigin: CatalogViewOrigin) {
		this.catalogViewOrigin = catalogViewOrigin
	}

	setSearchResultHistory(searchResultPage: number, searchResultPosition: number): void {
		this.searchResultPage = searchResultPage
		this.searchResultPosition = searchResultPosition
	}

	resetCatalogHistoryState(): void {
		this.searchResultPage = null;
		this.searchResultPosition = null;
	}

	/** State before a search is triggered */
	getSearchHistoryState(): ISearchHistoryState {
		return {
			searchOrigin: this.searchOrigin,
			searchSuggestionSource: this.searchSuggestionSource
		}
	}

	// TODO: Ideally, ISearchHistoryState would be set in a single method
	setSearchOrigin(searchOrigin: SearchOrigin): void {
		this.searchOrigin = searchOrigin;
	}

	setSearchSuggestionSource(searchSuggestionSource: SearchSuggestionSource) {
		this.searchSuggestionSource = searchSuggestionSource
	}

	setCatalogSuggestionSource(catalogSuggestionSource: CatalogSuggestionSource) {
		this.catalogSuggestionSource = catalogSuggestionSource
	}

	hasSearchOrigin(): boolean {
		return !isNullish(this.searchOrigin);
	}

	/** State before certain event types are triggered, which happen after a search */
	getPostSearchEventHistoryState(): IPostSearchEventHistoryState {
		return {
			searchGuid: this.searchGuid
		}
	}

	setPostSearchEventHistoryState(state: IPostSearchEventHistoryState): void {
		this.searchGuid = state.searchGuid
	}

	public endSearch() {
		this.searchGuid = null;
	}
}
