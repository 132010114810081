export enum ViewerNavControlEnum {
	SPACER = 0,
	ZOOMIN = 1,
	ZOOMOUT = 2,
	RESET = 3,
	TOP = 4,
	FRONT = 5,
	SIDE = 6,
	DOWNLOAD = 7,
	FULLSCREEN = 8,
	VIEW_2D = 9,
	VIEW_3D = 10
}
