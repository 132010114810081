import { Injectable } from '@angular/core';
import { isNullish } from '@commonHelpers/math-utils';
import { BehaviorSubject } from 'rxjs';
import { GlobalVarService } from './global-var.service';

@Injectable({
	providedIn: 'root'
})
export class ReferrerService {

	referrer = new BehaviorSubject('')

	constructor(private globalVarService: GlobalVarService) {
		this.initializeFromSessionStorage()
	}

	private get integrationKeyFromUrl() {
		return new URLSearchParams(window.location.search).get("integrationKey")
	}

	set(value: string) {
		this.globalVarService.setSessionStorageVar(`referrer-${this.integrationKeyFromUrl}`, JSON.stringify(value))
		this.referrer.next(value)
	}

	getAsObservable() {
		return this.referrer.asObservable()
	}

	private initializeFromSessionStorage() {
		// Website-Integration: Prüfen, ob im Session Storage für das entsprechende Fenster in Referer hinterlegt ist und gegebenenfalls nachladen.
		// Die Zwischenspeicherung im Session Storage  wird benötigt, weil die Informationen im referrerService sonst bei der Anmeldung durch den Page-Reload verloren gehen würden.
		// Der Session Storage wird verwendet, damit die Settings nur innerhalb eines Tabs und nicht tabübergreifend gespeichert werden.
		// Falls mehrere IFrames auf derselben Seite eingebunden werden,erfolgt die Zuordnung der Settings über den integrationKey, der als QueryParameter mit übergeben wird.
		// So kann sicher gestellt werden, dass die Settings dem richtigen Iframe zugeordnet werden.
		const persistedValue = this.globalVarService.getSessionStorageVar(`referrer-${this.integrationKeyFromUrl}`)
		if (!isNullish(persistedValue)) {
			this.referrer.next(JSON.parse(persistedValue))
		}
	}
}
