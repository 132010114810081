<div class="position-relative">
	<div class="position-absolute header-navigation" (blur)="closeOnOutside($event)" tabindex="0">
		<div class="header-menu bg-white" [ngClass]="[currentStateClass, viewTypeClass]">
			<div class="d-flex h-100 flex-column justify-content-between">
				<div class="pt-4 ps-4 ms-1">
					@if (showCapture) {
						@for (capture of model; track capture) {
							<div>
								<div class="fw-bold pb-2">{{ 'header-sidebar.' + capture.keypart + '.title' | translate }}</div>
								<div class="d-flex flex-column mt-2 ms-3 mb-3">
									@for (link of capture.links; track link) {
										@if (link.route && !isPreview) {
											<a
												class="pb-2 link-navigation"
												(click)="closeDialog()"
												[routerLink]="link.route"
												[target]="link.target">
												{{ 'header-sidebar.' + capture.keypart + '.' + link.keypart | translate }}</a
											>
										}
										@if (link.route && isPreview) {
											<div class="pb-2 link-navigation" (click)="showPreviewWarning()">
												{{ 'header-sidebar.' + capture.keypart + '.' + link.keypart | translate }}
											</div>
										}
										@if (link.href) {
											<a
												class="pb-2 link-navigation"
												(click)="closeDialog()"
												rel="noreferrer"
												[target]="link.target"
												[href]="link.href">
												{{ 'header-sidebar.' + capture.keypart + '.' + link.keypart | translate }}</a
											>
										}
										@if (link.customKey === 'cookie-settings') {
											<div (click)="openCookieSettings()" class="pb-2 link-navigation cursor-pointer">
												{{ 'header-sidebar.' + capture.keypart + '.' + link.keypart | translate }}
											</div>
										}
									}
								</div>
							</div>
						}
					}
				</div>
				@if (currentView === viewType.Desktop && !isInIntegrationMode) {
					<div class="infoletter-container w-100 d-flex pb-4 flex-column align-items-center">
						<img
							src="/assets/orca/header/infoletter-anmelden.svg"
							style="width: 252px; height: 192px"
							class="p-3"
							alt="infoletter"
							loading="lazy"
							decoding="async" />
						<div class="fw-bold mb-2">{{ 'header-sidebar.infoletter.planer' | translate }}</div>
						<lib-button
							variant="primary"
							(clicked)="infoLetterClicked()"
							(mousedown)="loginMouseDown($event)"
							[linkUrl]="infoletterLink"
							target="_blank"
							rel="noreferrer">
							{{ 'header-sidebar.infoletter.login' | translate }}</lib-button
						>
					</div>
				}
			</div>
		</div>
	</div>
</div>
