import { HostListener, Injectable } from '@angular/core';
import { generateGuid } from '@app/helpers/guid-generator';
import { isNullish } from '@commonHelpers/math-utils';
import { LoginSource } from '@interfaces/HttpClient/AnalyticsApiTrackingModels';
import { BehaviorSubject, Subscription, interval, map, timer } from 'rxjs';
import { GlobalVarService } from './global-var.service';

@Injectable({
	providedIn: 'root'
})
export class SessionService {


	private sessionServiceSubscription: Subscription;

	private session = new BehaviorSubject<ILocalSessionItem>(null)
	private readonly expireTimeInMinutes = 30;

	constructor(
		private globalVarService: GlobalVarService) {
	}

	private setSession(value: ILocalSessionItem) {
		this.globalVarService.setSession(value);
		this.session.next(value)
	}


	public setLoginSource(source: LoginSource) {
		const session = this.globalVarService.getSession()
		if (!isNullish(session)) {
			session.source = source.toString();
			this.session.next(session);
		}
		this.globalVarService.setSession(session)
	}

	public deleteLoginSource() {
		const session = this.globalVarService.getSession()
		if (!isNullish(session)) {
			session.source = null;
			this.session.next(session);
		}
		this.globalVarService.setSession(session)
	}

	public init(): void {
		this.checkSession();
		const now = new Date();
		const secondsUntilEndOfDate = (24 * 60 * 60) - (now.getHours() * 60 * 60) - (now.getMinutes() * 60) - now.getSeconds() + 1;
		// Teste Session um Mitternacht
		timer(secondsUntilEndOfDate * 1000).subscribe(() => {
			this.checkSession();
			// Teste Session dann alle 24 Stunden
			interval(24 * 60 * 60 * 1000).subscribe(() => {
				this.checkSession();
			})
		})
	}

	private checkSession() {
		const persistedValue = this.globalVarService.getSession()
		if (isNullish(persistedValue) || this.isSessionExpired(persistedValue)) {
			this.generateNewSession();
			const newValue = this.globalVarService.getSession();
			this.session.next(newValue)
			return
		}

		this.session.next(persistedValue)
	}

	private isSessionExpired(session: ILocalSessionItem): boolean {
		const currentTime = new Date();
		if (!isNullish(session) && (new Date(session.expire).getTime() < currentTime.getTime() || new Date(session.expireTomorrow).getTime() < currentTime.getTime())) {
			this.globalVarService.deleteSession();
			return true
		}
		return false
	}

	public getAsObservable() {
		return this.session.asObservable()
			.pipe(map((session) => this.isSessionExpired(session) ? null : session))
	}


	public getOrCreateSessionId(isAppInitializing: boolean = false): string {
		const currentSession = this.globalVarService.getSession();
		const lsLoginrequest = this.checkExpiryAndGetLocalState();
		const localStorageExpireDate = this.getNewStorageExpireDate();
		const today = new Date()
		const startOfToday = new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
		// Session wird gelöscht weil eine halbe Stunde vergangen ist bzw. muss neu angelegt werden
		if (isNullish(currentSession) ||
			// Session soll für den nächsten Tag gelöscht werden
			(new Date(lsLoginrequest?.expireTomorrow)).getTime() < startOfToday.getTime() ||
			// Session soll Tabübergreiffend 
			((!this.globalVarService.hasAnotherTab() &&
				// sowie während des Loginforgangs vorhanden sein
				isNullish(lsLoginrequest?.source)) && isAppInitializing)

		) {
			const newSessionGuid = generateGuid();
			this.setSession({
				guid: newSessionGuid,
				expireTomorrow: this.tomorrow,
				expire: localStorageExpireDate,
				source: lsLoginrequest?.source
			})
			return newSessionGuid;
		}
		// session behalten, nur expire time aktualisieren
		this.setSession({
			...currentSession,
			expire: localStorageExpireDate
		})

		// Sicher stellen das session NIEMALS Null ist.
		if (isNullish(currentSession?.guid)) {
			this.generateNewSession();
			const newSession = this.globalVarService.getSession();
			this.session.next(newSession);
			return newSession?.guid;
		}

		return currentSession?.guid;
	}

	private getNewStorageExpireDate() {
		const result = new Date();
		result.setMinutes(new Date().getMinutes() + this.expireTimeInMinutes);
		return result;
	}

	public generateNewSession() {
		this.setSession({
			guid: generateGuid(),
			expireTomorrow: this.tomorrow,
			expire: this.getNewStorageExpireDate(),
			source: null
		})
	}

	private tomorrow = (() => {
		const today = new Date()
		const tomorrow = new Date(today)
		tomorrow.setHours(0, 0, 0, 0);
		return new Date(tomorrow.setDate(tomorrow.getDate() + 1))
	})()

	private checkExpiryAndGetLocalState(): ILocalSessionItem {
		const lsItem = this.globalVarService.getSession();

		if (isNullish(lsItem)) { return null }
		const currentTime = new Date();
		if (new Date(lsItem.expire).getTime() < currentTime.getTime()) {
			this.globalVarService.deleteSession();
			return null
		}
		return lsItem;
	}

	public getLoginSource(): LoginSource {
		const user = this.globalVarService.getSession();
		return isNullish(user?.source) ? null : +user?.source;
	}

	@HostListener('window:beforeunload')
	destroy(): void {
		this.sessionServiceSubscription?.unsubscribe();
	}
}

export interface ILocalSessionItem {
	guid: string,
	expire: Date,
	expireTomorrow: Date,
	source: string,
	pageAvailableFlag?: number,
	openPagesFlag?: number
}
