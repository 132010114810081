import { Injectable } from '@angular/core';
import { InvalidArgumentException } from '@app/exceptions/invalid-argument-exception';
import { TrackAttachmentDtoClass, TrackCatalogDtoClass, TrackCatalogExternalDtoClass, TrackCatalogItemDtoClass, TrackCatalogListingDtoClass, TrackCatalogSuggestionDtoClass, TrackContactFormOpenDtoClass, TrackContactFormSubmitDtoClass, TrackExportDtoClass, TrackLoginRequestDtoClass, TrackLoginSuccessDtoClass, TrackSearchCatalogDtoClass, TrackSearchGlobalDtoClass, TrackShareCatalogItemDtoClass } from '@app/models/tracking/track-dtos';
import { TrackBaseDto } from '@interfaces/HttpClient/AnalyticsApiTrackingModels';
import { interval } from 'rxjs';
import { TrackEventService } from './track-event.service';

@Injectable({
	providedIn: 'root'
})
export class AnalyticsTrackingQueueService {
	private queue: TrackBaseDto[] = [];
	private isProcessing = false;

	constructor(private trackEventService: TrackEventService) { }

	public init() {
		interval(5000).subscribe(async () => this.processQueue());
	}

	public addToQueue(item: TrackBaseDto): void {
		this.queue.push(item);
	}

	public async commitAllTrackEvents() {
		while (this.queue.length > 0) {
			await this.processQueue();
		}
	}

	private async processQueue(): Promise<void> {
		if (this.isProcessing || this.queue.length === 0) {
			return;
		}

		this.isProcessing = true;

		while (this.queue.length > 0) {
			const item = this.queue.shift();
			await this.trackEvent(item)
		}

		this.isProcessing = false;
	}

	private async trackEvent(item: TrackBaseDto): Promise<void> {
		if (item instanceof TrackAttachmentDtoClass) {
			await this.trackEventService.trackAttachment(item);
		} else if (item instanceof TrackExportDtoClass) {
			await this.trackEventService.trackExport(item);
		} else if (item instanceof TrackCatalogDtoClass) {
			await this.trackEventService.trackCatalog(item);
		} else if (item instanceof TrackCatalogItemDtoClass) {
			await this.trackEventService.trackCatalogItem(item);
		} else if (item instanceof TrackCatalogListingDtoClass) {
			await this.trackEventService.trackCatalogListing(item);
		} else if (item instanceof TrackCatalogSuggestionDtoClass) {
			await this.trackEventService.trackCatalogSuggestion(item);
		} else if (item instanceof TrackCatalogExternalDtoClass) {
			await this.trackEventService.trackCatalogExternal(item);
		} else if (item instanceof TrackContactFormOpenDtoClass) {
			await this.trackEventService.trackContactFormOpen(item);
		} else if (item instanceof TrackContactFormSubmitDtoClass) {
			await this.trackEventService.trackContactFormSubmit(item);
		} else if (item instanceof TrackLoginRequestDtoClass) {
			await this.trackEventService.trackLoginRequest(item);
		} else if (item instanceof TrackLoginSuccessDtoClass) {
			await this.trackEventService.trackLoginSuccess(item);
		} else if (item instanceof TrackSearchGlobalDtoClass) {
			await this.trackEventService.trackSearchGlobal(item);
		} else if (item instanceof TrackSearchCatalogDtoClass) {
			await this.trackEventService.trackSearchCatalog(item);
		} else if (item instanceof TrackShareCatalogItemDtoClass) {
			await this.trackEventService.trackShareCatalogItem(item);
		} else {
			throw new InvalidArgumentException();
		}
	}
}
