import { NgTemplateOutlet } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit } from "@angular/core";
import { ToResourceKeyPipe } from "@app/pipes/toresourcekey";
import { NgbTooltip } from "@commonNodeModules/@ng-bootstrap/ng-bootstrap";
import { TranslateModule } from "@commonNodeModules/@ngx-translate/core";
import { AdditionalInfoDto, DisplaySetting } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { AccountService } from "@services/account.service";
import { HasMarkedTextDirective } from "../../directives/has-marked-text.directive";


@Component({
	selector: "app-additionalinfoviewer",
	templateUrl: "./additionalinfoviewer.component.html",
	styleUrls: ["./additionalinfoviewer.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
	standalone: true,
	imports: [HasMarkedTextDirective, ToResourceKeyPipe, TranslateModule, NgbTooltip, NgTemplateOutlet]
})

export class AdditionalinfoviewerComponent implements OnInit, OnChanges {
	@Input() additionalInfoDtos: AdditionalInfoDto[];
	@Input() catalogViewShowPrice: DisplaySetting;
	public additionalInfos: AdditionalInfoDto[];
	public costGroups: AdditionalInfoDto[];
	public collapsed = true;
	public loggedIn = false;
	public displaySetting = DisplaySetting;

	constructor(
		private accountService: AccountService,
	) { }

	ngOnInit() {
		this.loggedIn = this.accountService.isLoggedIn();
	}

	ngOnChanges() {
		this.additionalInfos = this.additionalInfoDtos.filter(e => e.title !== 'din276' && e.title !== 'workcategory')
		this.costGroups = this.additionalInfoDtos.filter(e => e.title === 'din276' || e.title === 'workcategory')
	}
}
