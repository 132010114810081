/* eslint-disable max-classes-per-file */
/* eslint-disable @typescript-eslint/no-empty-interface */

import { SearchSuggestionSource } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";

export interface ITrackHistoryParamsBase { }
export interface INavigateEvent extends ITrackHistoryParamsBase { }
export interface ISearchSuggestionSourceChangedParams extends ITrackHistoryParamsBase {
	/** Gibt an, auf welche Weise in der GUI die Suche ausgelöst wurde. */
	searchSuggestionSource: SearchSuggestionSource
}

export interface ISearchGuidChangedParams extends ITrackHistoryParamsBase {
	/** Der Unique Identifier der zuletzt ausgelösten Suche. */
	searchGuid: string
}


export interface INavigateNoResultParams extends ITrackHistoryParamsBase {
	/** Ein möglicher catalogSchlüssel zur Auflösung der Suche */
	catalogKey?: string
}

export interface INavigateSearchbarParams extends ITrackHistoryParamsBase {
	/** Überprüfung ob über den Suchterm gesucht wurde */
	useSearchTerm?: boolean
}

export interface INavigateFromSearchResult extends ITrackHistoryParamsBase {
	/** Bei Zugriff aus einer Suchtrefferliste: Angabe der Seite in der Suchtefferliste, von der aus der Zugriff erfolgt. */
	searchResultPage: number,

	/** Bei Zugriff aus einer Suchtrefferliste: Position in der Suchtefferliste, von der aus der Zugriff erfolgt. */
	searchResultPosition: number
}


export class TrackHistoryParamsBase implements ITrackHistoryParamsBase { }

// Navigation Events
export class NavigateHomeParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class NavigateAzParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class NavigateCategoryParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class NavigateInternationalCatalogParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class NavigateLinkedCatalogParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class NavigateLogoCarouselParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class NavigateLogoWallParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class NavigateSearchResultParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class NavigateSearchResultIfNotSetParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class NavigateSearchSuggestionParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class NavigateFromSearchbarIntoCatalog extends TrackHistoryParamsBase implements INavigateEvent { }

export class SetCategorySearchSuggestionSourceParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class SetStartpageSearchSuggestionSourceParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class SetSearchResultCatalogSuggestionSourceParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class ResetLoginParams extends TrackHistoryParamsBase implements INavigateEvent { }

export class NavigateCatalogParams extends TrackHistoryParamsBase implements INavigateEvent {
	constructor(public isInSearchView: boolean) {
		super();
	}
}

export class NavigateFromGlobalSearchCatalogResultToCatalogParams extends TrackHistoryParamsBase implements INavigateEvent {
	constructor() {
		super();
	}
}

export class NavigateFromGlobalSearchCatalogItemResultToCatalogParams extends TrackHistoryParamsBase implements INavigateEvent {
	constructor(public searchResultPage: number, public searchResultPosition: number) {
		super();
	}
}

export class NavigateFromCatalogSearchToCatalogSearchResultParams extends TrackHistoryParamsBase implements INavigateEvent {
	constructor(public indexedTerm: string, public term: string, public isSuggestionClicked: boolean) {
		super();
	}
}

export class NavigateFromCatalogSearchCatalogItemResultToCatalogItemParams extends TrackHistoryParamsBase implements INavigateEvent {
	constructor(public searchResultPage: number, public searchResultPosition: number) {
		super();
	}
}

// Tracking: Globale Suche
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/860/SearchGlobal
export class SearchSuggestionSourceChangedParams extends TrackHistoryParamsBase implements ISearchSuggestionSourceChangedParams {
	constructor(public searchSuggestionSource: SearchSuggestionSource) {
		super();
	}
}



export class SearchGuidChangedParams extends TrackHistoryParamsBase implements ISearchGuidChangedParams {
	constructor(public searchGuid: string) {
		super();
	}
}

// Tracking: SearchSuggestionSource => SearchTerm oder LinkedCatalog
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/861/SearchCatalog
export class NavigateNoResultParams extends TrackHistoryParamsBase implements INavigateNoResultParams {
	constructor(public catalogKey?: string) {
		super();
	}
}

export class NavigateSearchbarParams extends TrackHistoryParamsBase implements INavigateSearchbarParams {
	constructor(public useSearchTerm?: boolean) {
		super();
	}
}
