import { Injectable } from '@angular/core';
import { isNullish } from '@commonHelpers/math-utils';
import { environment } from '@environments/environment';
import { globalEnvironment } from '@environments/globalEnvironment';
import { GlobalVarService } from './global-var.service';

@Injectable()
export class CacheService {
	public static isStable = false;
	constructor(private globalVarService: GlobalVarService) {
	}

	public async checkForUpdates() {
		this.globalVarService.insertFormerWatchlistAndClearStorage()
		if (environment.production) {
			if (isNullish(this.globalVarService.getCurrrentBuild())) {
				// TODO: Löschen wenn bei allen Kollegen der Altcache weg ist bzw. wir Live gegangen sind und es keinen korrukten Altcache für ausschreiben.de mehr gibt.
				const registrations = await this.getRegistrations();
				if (registrations?.length > 0) {
					await this.clearCacheAndReload();
					return;
				}
				this.globalVarService.setCurrrentBuild(globalEnvironment.buildDate?.toString())
			} else if (this.globalVarService.getCurrrentBuild() !== globalEnvironment.buildDate) {
				await this.clearCacheAndReload();
				return;
			}
		}
	}


	public async clearCacheAndReload() {
		try {
			const registrations = await this.getRegistrations();
			if (registrations) {
				await Promise.all(registrations.map(async registration => registration.unregister()))
			}
			const cache = await caches.keys();
			await Promise.all(cache.map(async cacheName => caches.delete(cacheName)))
			this.globalVarService.setCurrrentBuild(globalEnvironment.buildDate?.toString());
		} catch (e) {
		}
		document.location.reload();
	}

	// Firefox mit der Einstellung das cookie beim Schließen gelöscht werden müssen werfen bei der Abfrage nach SW-Registrierung einen Fehler
	private async getRegistrations(): Promise<readonly ServiceWorkerRegistration[]> {
		try {
			return await navigator.serviceWorker?.getRegistrations();
		} catch (e) {
			return []
		}
	}
}