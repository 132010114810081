// ACHTUNG!!! SEO-kritische Komponente, Direkte Importe und Backendaufrufe sollten so gering wie nur möglich gehalten werden 


import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { TrackCatalogItemParams, TrackCatalogParams } from "@app/models/tracking/track-event-param-type";
import { NavigateCatalogParams, NavigateSearchbarParams } from "@app/models/tracking/track-history-param-type";
import { ViewType } from "@enums/view-type";
import { AnalyticsTrackingFacadeService } from "@services/analytics-tracking-facade.service";
import { TranslationService } from "@services/translation.service";
import { ViewTypeService } from "@services/view-type.service";
import { Subscription } from "rxjs";
import { CatalogDesktopComponent } from "./catalog-desktop/catalog-desktop.component";
import { CatalogMobileComponent } from "./catalog-mobile/catalog-mobile.component";

@Component({
	selector: "app-catalog",
	templateUrl: "./catalog.component.html",
	styleUrls: ["./catalog.component.scss"],
	standalone: true,
	imports: [
    CatalogMobileComponent,
    CatalogDesktopComponent
]
})

export class CatalogComponent implements OnInit, OnDestroy {
	public currentViewType: ViewType;
	private routeSubscription: Subscription;
	private viewTypeSubscription: Subscription;
	public viewType = ViewType;

	constructor(
		private viewTypeService: ViewTypeService,
		private trackingFacadeService: AnalyticsTrackingFacadeService,
		private route: ActivatedRoute,
		private translationService: TranslationService
	) {
	}


	ngOnInit() {
		// Tracking bei Katalogen
		if (this.route.snapshot.params.view === "search") {
			this.trackingFacadeService.handleTrackHistory(new NavigateSearchbarParams(false))
		}
		this.trackingFacadeService.handleTrackHistory(new NavigateCatalogParams(this.route.snapshot.params.view === "search"));
		let trackCatalogKey: string;
		let trackCatalogGuid: string;
		this.routeSubscription = this.route.params.subscribe(params => {
			if (trackCatalogKey !== params.key) {
				this.trackingFacadeService.handleTrackEvent(new TrackCatalogParams(params.key));
				trackCatalogKey = params.key;
			}
			if (trackCatalogGuid !== params.catalogItemGuid) {
				this.trackingFacadeService.handleTrackEvent(new TrackCatalogItemParams(params.key, params.catalogItemGuid));
				trackCatalogGuid = params.catalogItemGuid;
			}

			if (params.view !== "search") {
				this.trackingFacadeService.endSearch();
			}
		})
		this.viewTypeSubscription = this.viewTypeService.currentViewType$.subscribe(async viewType => {
			if (this.currentViewType !== viewType) {
				this.currentViewType = viewType;
			}
		});
	}

	async ngOnDestroy() {
		this.routeSubscription?.unsubscribe();
		await this.translationService.setDefaultLanguage();
		this.viewTypeSubscription?.unsubscribe();
	}
}
