import { Pipe, PipeTransform } from "@angular/core";

///
/// Pipe, die ein Enum aus der CatalogAPI in einen zu übersetzenden ResourceKey transformiert
///
@Pipe({
    name: "toResourceKey",
    standalone: true
})
export class ToResourceKeyPipe implements PipeTransform {
	transform(value: string, keyPrefix: string): string {
		let result = "";
		if (keyPrefix) {
			result = result + keyPrefix;
		}
		if (value) {
			result = result + value.toLowerCase();
		}
		return result;
	}
}
