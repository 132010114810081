import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

export interface ContextMenuState {
	positionX: number;
	positionY: number;
	show: boolean;
	safariCall?: boolean;
	copy?: boolean;
}

@Injectable()
export class ContextMenuService {


	private subject = new Subject<ContextMenuState>();
	private currentState?: ContextMenuState;

	constructor() { }

	openMenu(positionX: number, positionY: number): void {
		this.currentState = {
			positionX,
			positionY,
			show: true
		}
		this.subject.next(
			this.currentState
		);
	}

	getCurrentState() {
		return this.currentState;
	}

	closeMenu(): void {
		this.currentState = {
			positionX: null,
			positionY: null,
			show: false
		}
		this.subject.next(
			this.currentState
		)
	}

	copySelectedClick() {
		this.currentState = {
			positionX: null,
			positionY: null,
			show: false,
			copy: true
		}
		this.subject.next(
			this.currentState
		);
	}

	getMenuState(): Observable<ContextMenuState> {
		return this.subject.asObservable();
	}
}
