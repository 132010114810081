import { Injectable } from "@angular/core";



@Injectable({
	providedIn: "root"
})
export class MaintenanceDeactivateGuard {
	constructor() { }

	async canDeactivate() {

		return true;// !environment.maintenance;
	}
}
