/* eslint-disable @typescript-eslint/no-empty-interface */
// remove eslint-disable after all stubs have been filled
// eslint-disable-next-line max-classes-per-file
import { IContact } from "@app/components/contact-form/contact-form.component";
import { ISearchFeedback } from "@app/components/search-feedback-form/search-feedback-form.component";
import { generateGuid } from "@app/helpers/guid-generator";
import { CatalogListingSource, ExportSource, FormatId, LoginSource } from "@interfaces/HttpClient/AnalyticsApiTrackingModels";
import { ContactFormType } from "@interfaces/HttpClient/CatalogApiPublicModels";
import { CatalogSearchRequest } from "@interfaces/SearchRequest";
import { ContactFormIntentions } from "../../interfaces/HttpClient/AnalyticsApiTrackingModels";

export interface ITrackEventParamsBase { }

export interface ITrackCatalogKeyParamBase extends ITrackEventParamsBase {
	/** Der Katalog-Schlüssel des Katalogs, wie er auch in der URL vorkommt. */
	catalogKey?: string;
}

export interface ITrackCatalogKeyAndItemGuidParamBase extends ITrackCatalogKeyParamBase {
	/**	Der unique Identifier der Position.	*/
	catalogItemGuid?: string;
}

export interface ITrackAttachmentParams extends ITrackCatalogKeyAndItemGuidParamBase {
	/**	Der unique Identifier des Anhangs. */
	attachmentId: number;

	/**	Gibt an, ob der Anhang im Fullscreen-Modus geöffnet wurde */
	isLargeViewer: boolean;

	/**	Gibt an, ob der Anhang detailliert betrachtet wurde, 
	 * konkret: ob ein Video tatsächlich abgespielt wurde bzw. eine CAD- oder BIM-Datei tatsächlich im Viewer geöffnet wurde. */
	isExplicitlyViewed: boolean;
}

export interface ITrackCatalogParams extends ITrackCatalogKeyParamBase {
	/** Gibt an, ob es sich um einen expliziten Aufruf der Katalog-Informationen handelt */
	isCatalogInformation: boolean;
}

export interface ITrackExportParams extends ITrackCatalogKeyAndItemGuidParamBase {
	/**	Der unique Identifier des Anhangs. */
	attachmentId?: number;

	/** Das ausgewählte Format für den Export: */
	exportFormat: FormatId;

	/** Die Stelle in der App, an der dieser Export getriggert wurde */
	source: ExportSource;
}

export interface ITrackExportsParams extends ITrackEventParamsBase {
	/** Liste der Parameter aller gesammelt zu trackender Exporte */
	exports: ITrackExportParams[]
}

export interface ITrackCatalogItemParams extends ITrackCatalogKeyAndItemGuidParamBase { }

export interface ITrackCatalogListingParams extends ITrackEventParamsBase {
	/** Die catalogKeys der Kataloge, die innerhalb des jeweiligen 5 Sekunden-Abschnitts auf dem Bildschirm erschienen sind, als kommaseparierte Liste. */
	catalogKeys: string[];
	catalogListingSource: CatalogListingSource;
}

export interface ITrackCatalogSuggestionParams extends ITrackEventParamsBase {
	/** Die catalogKeys der Kataloge, die innerhalb des jeweiligen 5 Sekunden-Abschnitts auf dem Bildschirm erschienen sind, als kommaseparierte Liste. */
	catalogKeys: string[];
}

export interface ITrackCatalogExternalParams extends ITrackCatalogKeyParamBase {
	/** Typ des externen Link-Aurufs */
	type: number;

	/** Url des externen Links, zu der navigiert wurde */
	targetUrl: string;
}

export interface ITrackContactFormOpenParams extends ITrackCatalogKeyAndItemGuidParamBase {
	/**	Die Art des Kontaktformulars */
	type: ContactFormType;
}

export interface ITrackContactFormSubmitParams extends ITrackCatalogKeyAndItemGuidParamBase {

	/**	Angeklickte Checkboxen "Ich möchte" */
	intentions: ContactFormIntentions[];
	/**	Das Model  des Kontaktformulars */
	contact: IContact;

	/**	Die Art des Kontaktformulars */
	type: ContactFormType;
}

export interface ITrackGlobalSearchSubmitParams extends ITrackCatalogKeyAndItemGuidParamBase {
	/**	Das Model  des Kontaktformulars */
	contact: ISearchFeedback;
}

export interface ITrackLoginRequestParams extends ITrackEventParamsBase {
	/** Von wo aus wurde der Login gestartet */
	source: LoginSource;

	/** Katalogschlüssel **/
	catalogKey?: string;

	/** Katalogguid **/
	catalogItemGuid?: string;

	/** Attachment **/
	attachmentId?: number
}

export interface ITrackLoginSuccessParams extends ITrackEventParamsBase {
}

export interface ITrackSearchGlobalParams extends ITrackEventParamsBase {
	/** Suchtext, der in das Suchfeld eingegeben wurde. */
	searchText: string;

	/** In der Suche gesetzte Filter, sofern vorhanden. */
	searchFilter: string;

	/** Der Unique Identifier des Suchvorgangs. */
	searchGuid: string;

	/** Seitenzahl der Suchergebnisseite. */
	pageIndex: number;

	/** Seitengröße der Suchergebnisseiten. */
	pageSize: number;

	/** Anzahl der Kataloge des Suchergebnisses. */
	countCatalogs: number;

	/** Anzahl der CatalogItems des Suchergebnisses. */
	countCatalogItems: number;
}

export interface ITrackSearchCatalogParams extends ITrackCatalogKeyParamBase {

	/** Die Anfrage zum Entsprechenden Tracking als weiterleitung */
	catalogSearchRequest: CatalogSearchRequest

	/** Der Unique Identifier des Suchvorgangs. */
	searchGuid: string;

	/** Anzahl der CatalogItems des Suchergebnisses. */
	countCatalogItems: number;
}

export interface ITrackShareCatalogItemParams extends ITrackCatalogKeyAndItemGuidParamBase {
	/** Auf welche Art wurde die Position geteilt: */
	shareCatalogItemType: number;
}

export class TrackEventParamsBase implements ITrackEventParamsBase { }

// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/812/Attachment
export class TrackAttachmentParams extends TrackEventParamsBase implements ITrackAttachmentParams {
	constructor(
		public catalogKey: string,
		public catalogItemGuid: string,
		public attachmentId: number,
		public isLargeViewer: boolean,
		public isExplicitlyViewed: boolean
	) {
		super();
	}
}

// Tracking:
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/844/Catalog
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/846/CatalogItem
export class TrackCatalogParams extends TrackEventParamsBase implements ITrackCatalogParams {
	constructor(public catalogKey: string, public isCatalogInformation: boolean = false) {
		super()
	}
}

// Tracking:
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/813/Export
export class TrackExportParams extends TrackEventParamsBase implements ITrackExportParams {
	constructor(
		public catalogKey: string,
		public catalogItemGuid: string,
		public attachmentId: number,
		public exportFormat: FormatId,
		public source: ExportSource
	) {
		super();
	}
}

export class TrackExportsParams extends TrackEventParamsBase implements ITrackExportsParams {
	constructor(public exports: ITrackExportParams[]) {
		super();
	}
}

// Tracking:
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/846/CatalogItem
export class TrackCatalogItemParams extends TrackEventParamsBase implements ITrackCatalogItemParams {
	constructor(public catalogKey: string, public catalogItemGuid: string) {
		super();
	}
}
// Tracking:
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/847/CatalogListing
export class TrackCatalogListingParams extends TrackEventParamsBase implements ITrackCatalogListingParams {
	constructor(public catalogKeys: string[], public catalogListingSource: CatalogListingSource) {
		super();
	}
}

// Tracking:
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/849/CatalogSuggestion
export class TrackCatalogSuggestionParams extends TrackEventParamsBase implements ITrackCatalogSuggestionParams {
	constructor(public catalogKeys: string[]) {
		super();
	}
}

// Tracking:
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/851/CatalogExternal
export class TrackCatalogExternalParams extends TrackEventParamsBase implements ITrackCatalogExternalParams {
	constructor(public catalogKey: string, public type: number, public targetUrl: string) {
		super();
	}
}

// Tracking:
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/852/ContactFormOpen
export class TrackContactFormOpenParams extends TrackEventParamsBase implements ITrackContactFormOpenParams {
	constructor(public type: ContactFormType, public catalogKey?: string, public catalogItemGuid?: string) {
		super();
	}
}

// Tracking:
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/854/ContactFormSubmit
export class TrackContactFormSubmitParams extends TrackEventParamsBase implements ITrackContactFormSubmitParams {
	constructor(
		public catalogKey: string,
		public catalogItemGuid: string,
		public contact: IContact,
		public type: ContactFormType,
		public intentions: ContactFormIntentions[]
	) {
		super();
	}
}

// Tracking:
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/857/LoginRequest
export class TrackLoginRequestParams extends TrackEventParamsBase implements ITrackLoginRequestParams {
	constructor(public source: LoginSource, public catalogKey?: string, public catalogItemGuid?: string, public attachmentId?: number) {
		super();
	}
}

export class TrackLoginSuccessParams extends TrackEventParamsBase implements ITrackLoginSuccessParams {
	constructor() {
		super();
	}
}

// Tracking: Globale Suche
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/860/SearchGlobal
export class TrackSearchGlobalParams extends TrackEventParamsBase implements ITrackSearchGlobalParams {
	constructor(
		public searchText: string,
		public searchFilter: string,
		public pageIndex: number,
		public pageSize: number,
		public countCatalogs: number,
		public countCatalogItems: number,
		public searchGuid = generateGuid()
	) {
		super();
	}
}

// Tracking: Katalog-Suche
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/861/SearchCatalog
export class TrackSearchCatalogParams extends TrackEventParamsBase implements ITrackSearchCatalogParams {
	constructor(
		public catalogSearchRequest: CatalogSearchRequest,
		public countCatalogItems: number,
		public searchGuid = generateGuid()
	) {
		super();
	}
}

// Tracking: Share Copy Link
// Falls sich das Tracking ändert bitte unbedingt die Dokumentation im Wiki anpassen:
// https://dev.azure.com/orcacloud/ADE/_wiki/wikis/ADE.wiki/862/ShareCatalogItem
export class TrackShareCatalogItemParams extends TrackEventParamsBase implements ITrackShareCatalogItemParams {
	constructor(
		public catalogKey: string,
		public catalogItemGuid: string,
		public shareCatalogItemType: number
	) {
		super();
	}
}

