import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { environment } from "@environments/environment";
import { MsalB2CInterceptor } from "./msalb2c.interceptor";

export const apiAccesses = [
	environment.accountApi,
	environment.analyticsApi,
	environment.catalogApi,
	environment.exportApi,
	environment.importApi,
	environment.naturalLanguageProcessingApi,
	environment.protocolApi,
	environment.searchApi,
	environment.ftpApi
];

export const interceptorProvider = {
	provide: HTTP_INTERCEPTORS,
	useClass: MsalB2CInterceptor,
	multi: true
}
