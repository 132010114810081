<div class="h-100 w-100 fit-content overflow-hidden pt-3">
	@if (catalog) {
		<app-catalog-details
			#catalogDetails
			[catalogDetailsType]="catalogDetailsType"
			[queryParams]="catalogQueryParams"
			[routingParams]="catalogRoutingParams"
			(contactFormOpen)="openContactForm($event)"
			[url]="url"
			[catalogKey]="catalog.key"
			[exportSettings]="catalog.exportSettings"
			[catalogViewShowPrice]="catalog.catalogViewShowPrice"
			[featureToggles]="catalog.featureToggles"
			[catalogTitle]="catalog?.title">
		</app-catalog-details>
	}
</div>
