import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PopupService {

  constructor() {
  }

  private loginProfitsSubject = new Subject<any>();

  public openLoginProfits(catalogKey) {
    this.loginProfitsSubject.next(catalogKey);
  }

  public getLoginProfitsDialog(): Observable<any> {
    return this.loginProfitsSubject.asObservable();
  }
}


