<header class="border-bottom border-gray-400" [ngClass]="theme === 'ade' ? 'bg-primary' : 'bg-white'" id="public-header">
	<div class="w-100 ps-4 pe-3">
		<div style="height: 3.8rem" class="d-flex gap-2 justify-content-between align-items-center">
			<div class="d-flex align-items-center gap-3">
				<!--Orca Logo-->
				<a (click)="signetClick.emit()" [routerLink]="['/']">
					<img src="assets/orca/header/signet.svg" loading="eager" decoding="async" alt="Orca logo" class="orca-logo" />
				</a>
				<!--Burgermenü-->
				<img
					src="/assets/orca/header/burger-menu.svg"
					class="burgermenu cursor-pointer"
					alt="burgermenu-navigation"
					loading="eager"
					decoding="async"
					(mouseup)="headerBurgerMenu.triggerMenu()"
					(mousedown)="headerBurgerMenu.triggerMenuMouseDown()" />
			</div>
			<div class="me-3 d-flex gap-3 justify-content-end align-items-center">
				<!--Merkliste wird in der SEOkritischen Mobileansicht beim Katalog immer angezeit. Deshalb KEINE deferabfrage-->
				@if (hasWatchlist) {
					<!--Merkliste-->
					<app-watchlist-header-navigation [isMobile]="true" [theme]="theme"> </app-watchlist-header-navigation>
				}
				@if (showLanguage) {
					<div class="language">
						@defer {
							<!--Spracheinstellung-->
							<app-language (languageChange)="languageChange.emit($event)" [isMobile]="true" />
						}
					</div>
				}
				<!--Hilfe-->
				<app-help-public></app-help-public>
				<!--Anmeldung-->
				<app-user-menu-context [catalogKey]="catalogKey" target="_blank" rel="noopener"> </app-user-menu-context>
			</div>
		</div>
	</div>
</header>
<!--Burgermenü aufplappen-->
<app-header-burger-menu #headerBurgerMenu class="h-100"> </app-header-burger-menu>
